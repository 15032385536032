import { Scrollbars } from 'rc-scrollbars';
import { List, Avatar, Badge } from 'antd';

const ContactList = ({ contacts, onSelectContact, selectedContactId }) => {
  return (
    <Scrollbars style={{ height: '100%' }}>
      <List
        itemLayout="horizontal"
        dataSource={contacts}
        locale={{ emptyText: 'No hay contactos disponibles.' }} // Mensaje si no hay contactos
        renderItem={(contact) => (
          <List.Item 
            onClick={() => onSelectContact(contact)}
            style={{ 
              cursor: 'pointer',
              backgroundColor: contact.id === selectedContactId ? '#e6f7ff' : 'transparent'
            }}
          >
            <List.Item.Meta
              avatar={
                <Badge count={contact.unreadCount} size="small" offset={[-5, 5]}>
                  <Avatar src={contact.avatar} />
                </Badge>
              }
              title={contact.name}
              //description={contact.lastMessage}
            />
          </List.Item>
        )}
      />
    </Scrollbars>
  );
};


export default ContactList;