import React from "react";

import "./ResetPasswordMailed.css";
import OuterNavBar from "../../OuterNavBar";
import { useLanguage } from "../../../utils/languageContext";
import { useNavigate } from "react-router-dom";
import handleAutoLogin from "../../behaviour/handleAutoLogin";
import handleHome from "../../behaviour/handleHome";
import ResetPasswordBox from "../../auth/resetPassword/ResetPasswordBox";

function ResetPasswordMailed() {
	const navigate = useNavigate(); // Para la navegación
	const { language, translations } = useLanguage();

	const handleLogin = () => {
		if (handleAutoLogin()) {
			handleHome(navigate);
		}
		navigate("/Login");
	};

	return (
		<div className="Login-page">
			<OuterNavBar
				rightUpperButton={translations[language].login_button}
				rightUpperButtonFunction={handleLogin}
			/>
			<ResetPasswordBox />
		</div>
	);
}

export default ResetPasswordMailed;
