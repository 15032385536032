import { useEffect, useState } from "react";
import { supabase } from '../../../../supabaseClient';
import NavBar from "../../../NavBar";
import { useParams } from "react-router-dom"; // Para obtener el ID de la actividad desde la URL
import DetailCard from "../../../DetailCard";
import { useNavigate } from 'react-router-dom';
import DeleteActivityButton from "../DeleteActivityButton";

export default function TuristicDetail() {
  const { id } = useParams(); // Obtenemos el id de la actividad desde la URL
  const [activity, setActivity] = useState(null); // Estado para la actividad
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivityDetail = async () => {
      const { data, error } = await supabase
        .from('activity_turistic') // Asegúrate de que el nombre de la tabla esté correcto
        .select(`name, description, image: id_image (base64image)`) // Selecciona los campos necesarios
        .eq('id', id) // Filtra por id de la actividad
        .single(); // Solo esperamos un resultado

      if (error) {
        console.error("Error fetching data: ", error);
      } else {
        // Guarda los detalles de la actividad en el estado
        setActivity({
          name: data.name,
          description: data.description,
          id_image: data.image.base64image,
        });
      }
    };

    fetchActivityDetail();
  }, [id]); // Ejecutar el efecto cuando cambie el id

  if (!activity) {
    return <div>Loading...</div>; // Mientras cargan los detalles
  }


  const handleDeleteConfirm = async () => {
    try {
      const { error } = await supabase
        .from('activity_turistic')
        .delete()
        .eq('id', id);

      if (error) throw error;

      console.log('Activity deleted successfully');
      alert("Actividad eliminada con exito.");
      navigate('/Actividades'); // Redirect to the benefits list page
    } catch (error) {
      console.error('Error deleting activity:', error);
    }
  };

  return (
    <div>
      {/* NavBar */}
      <NavBar sections={["Beneficios", "Actividades", "Hospedajes", "Conectarse", "Chats"]} />
      
      {/* Details */}
      <DetailCard title={activity.name} description={activity.description} image={activity.id_image} bgColor="bg-gray-purple"/>

      {/* Delete Floating Button */}
      <DeleteActivityButton handleDeleteConfirm={handleDeleteConfirm} />
    </div>
  );
}
