import React, { useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./quill.css";
import { useLanguage } from "../../../utils/languageContext";
import { translations } from "../../../utils/translations";

const QuillPrompt = ({ quillRef }) => {
  //const { language, toggleLanguage } = useLanguage();
  const { language } = useLanguage();
  useEffect(() => {
    if (quillRef.current === null) {
      quillRef.current = new Quill("#quill-editor", {
        modules: {
          toolbar: [
            [
              { font: [] },
              { header: [1, 2, 3, false] },
              "bold",
              "italic",
              "underline",
            ],
            [{ color: [] }, { background: [] }],
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
          ],
        },
        placeholder: translations[language].post_content,
        theme: "snow",
        formats: [
          "header",
          "list",
          "align",
          "bold",
          "italic",
          "underline",
          "background",
          "color",
          "font",
          "link",
          "image",
        ],
      });

      const Link = Quill.import("formats/link");
      Link.sanitize = (url) => {
        if (!/^https?:\/\//i.test(url)) {
          return `https://${url}`;
        }
        return url;
      };

      quillRef.current.getModule("clipboard").addMatcher("A", (node, delta) => {
        const href = node.getAttribute("href");
        if (href && !/^https?:\/\//i.test(href)) {
          node.setAttribute("href", `https://${href}`);
        }
        return delta;
      });
    }
  }, [quillRef, language]);

  return (
    <div className="quill-container">
      <div id="quill-editor" />
    </div>
  );
};

export default QuillPrompt;
