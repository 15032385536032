import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const ReceivedMessage = ({ message, username, time }) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '10px',
    }}>
      <div style={{
        backgroundColor: '#f0f0f0',
        color: 'white',
        padding: '10px 15px',
        borderRadius: '18px',
        maxWidth: '70%',
        wordWrap: 'break-word',
      }}>
        <Text strong style={{ color: '#333', marginBottom: '5px', display: 'block' }}>
          {username}
        </Text>
        <Text style={{ color: '#333' }}>
          {message}
        </Text>
        <div style={{
          textAlign: 'right',
          marginTop: '5px',
        }}>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {time}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ReceivedMessage;

