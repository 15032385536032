import React from 'react';
import { Typography, Divider } from 'antd';

const { Text } = Typography;

const DateDivider = ({ date }) => {
  return (
    <Divider plain style={{ color: '#444', fontSize: '14px' }}>
      <Text strong>{date}</Text>
    </Divider>
  );
};

export default DateDivider;