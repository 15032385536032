import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginBox.css";
import handleHome from "../../behaviour/handleHome";
import PasswordPrompt from "../components/password/passwordPrompt/PasswordPrompt";
import UsernamePrompt from "../components/user/UsernamePrompt";
import { useLanguage } from "../../../utils/languageContext";

const isUsernameAvailable = async (username) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/check-username/${username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // El body se enviará en formato JSON
        },
      }
    );

    const data = await response.json();
    return data.isTaken;
  } catch (error) {
    console.error("Error en la solicitud:", error);
    return true;
  }
};

const checkUsername = async (username) => {
  if (username == null || username.length < 4) {
    alert("Nombre de usuario no válido! Tiene que tener al menos 4 caracteres");
    return false;
  }
  if (!(await isUsernameAvailable(username))) {
    alert("El nombre de usuario no esta disponible! Por favor escoge otro.");
    return false;
  }
  return true;
};

const isPassword = (password) => {
  if (password == null || password.length < 8) {
    alert("Contraseña inválida!");
    return false;
  }
  return password.length > 7;
};

const LoginBox = ({ toggleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const checkData = async () => {
    if (!(await checkUsername(username)) || !isPassword(password)) {
      return;
    }

    const body = { username, password };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/validate_login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // El body se enviará en formato JSON
          },
          body: JSON.stringify(body), // Convertimos el objeto JS en una cadena JSON
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Usuario logeado con éxito:") /*, data)*/;
        document.cookie = `token=${data.token}; Max-Age=604800 ; path=/; secure; samesite=None;`;
      } else {
        alert("Error al logear usuario: " + data.message);
        return;
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      return;
    }

    handleHome(navigate);
  };

  const { language, translations } = useLanguage();


  return (
    <section className="login-box">
      <h1 id="title">
        {translations[language].login_button}
      </h1>
      <UsernamePrompt onUsernameChange={(e) => setUsername(e.target.value)} />
      <PasswordPrompt onPasswordChange={(e) => setPassword(e.target.value)} />
      <button id="forgot-pass" onClick={(e) => toggleLogin(!e)}>¿Olvidaste tu contraseña?</button>
      <input type="submit" value={translations[language].login_button} id="loginBtn" onClick={checkData} />
    </section>
  );
};

export default LoginBox;
