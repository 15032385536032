import React from "react";
import OuterNavBar from "../../OuterNavBar";
import { useNavigate } from "react-router-dom";
import handleHome from "../../behaviour/handleHome";
import handleAutoLogin from "../../behaviour/handleAutoLogin";
import { useLanguage } from "../../../utils/languageContext";
import StudentHubMemberCard from "../../StudentHubMembersCard";  // Import the UserCard component

export default function OurMissionPage() {
  const navigate = useNavigate();
  const { language, translations } = useLanguage();

  const handleLogin = async () => {
    if (handleAutoLogin()) {
      handleHome(navigate);
    }
    navigate("/Login");
  };

  const teamMembers = [
    { name: "Valentina Novillo", role: "Front-End Web Dev", image: "/MemberImages/FotoValen.jpeg" },
    { name: "Román Berruti", role: "Front-End Web Dev", image: "/MemberImages/FotoRoman.jpeg" },
    { name: "Matías Romanato", role: "Full-Stack Web Dev", image: "/MemberImages/FotoMati.jpeg" },
    { name: "Franco Bonesi", role: "Back-End Web Dev", image: "/MemberImages/FotoFran.jpeg" },
    { name: "Luca Rossi", role: "Back-End Web Dev", image: "/MemberImages/FotoLuca.jpeg" },
  ];

  return (
    <div className="relative min-h-screen overflow-hidden">
      <OuterNavBar
        rightUpperButton={translations[language].login_button}
        rightUpperButtonFunction={handleLogin}
      />

      <div className="flex flex-col min-h-full">
        {/* Título principal */}
        <div className="text-center mt-4 mb-8">
          <h1 className="text-4xl font-bold text-dark-grey font-roboto">{translations[language].our_mission}</h1>
        </div>

        <div className="flex-grow px-8 text-gray-purple">
          {/* Sección Problemática y Solución */}
          <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col justify-start items-center bg-nardo-gray rounded-lg shadow-lg p-8">
              <h2 className="text-3xl font-bold text-pearl mb-3">{translations[language].issue}</h2>
              <p className="text-base leading-5 text-center text-pearl">
                {translations[language].issue_detail}
              </p>
            </div>

            <div className="flex flex-col justify-start items-center bg-gray-purple rounded-lg shadow-lg p-8">
              <h2 className="text-3xl font-bold text-pearl mb-3">{translations[language].solution}</h2>
              <p className="text-base leading-5 text-center text-pearl">
                {translations[language].solution_detail}
              </p>
            </div>
          </div>

          {/* Separador entre secciones */}
          <div className="my-10 border-t-2 border-gray-purple"></div>

          {/* Sección Miembros */}
          <div className="text-center">
            <h2 className="text-3xl font-bold text-dark-grey mb-4">{translations[language].our_members}</h2>
            <div className="grid grid-cols-5 gap-4">
              {teamMembers.map((member, index) => (
                <StudentHubMemberCard
                  key={index}
                  name={member.name}
                  role={member.role}
                  image={member.image}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

