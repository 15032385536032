import React, { useState } from "react";

import "./ResetPassword.css";

import { useLanguage } from "../../../utils/languageContext";
import RegEmailPrompt from "../components/email/regInputBox/RegEmailPrompt";
import { isEmail, isMailAvailable } from "../../behaviour/email";

const checkMail = async (email) => {
	if (!isEmail(email)) return false;
	if (!(await isMailAvailable(email))) {
		alert("El mail no está registrado!");
		return false;
	}
	return true;
};

const ResetPassord = ({ toggleLogin }) => {
	const [email, setEmail] = useState("");
	const [domain, setDomain] = useState("");
	const [mailSent, setMailSent] = useState(false);

	const checkData = async () => {
		const fullMail = email + domain.replace(/ /g, "");
		const check = await checkMail(fullMail);

		if (check === false) {
			return;
		} else {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/users/reset_password`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({fullMail}),
					}
				);

				const data = await response.json();
				if (response.ok) {
          setMailSent(true);
					return;
				} else {
					console.error(
						"Error al enviar el mail de verificacion:",
						data.message
					);
				}
			} catch (e) {
				console.error("Error en la solicitud:", e);
			}
		}
	};

	const { language, translations } = useLanguage();

	return (
		<section className="reset-password-box">
			<h1 id="title">{translations[language].change_password}</h1>
			{mailSent ? (
				<>
					<p id="mail-sent">
						El mail fue enviado con éxito, revisa tu mail para cambiar la
						contraseña
					</p>
					<input
						type="submit"
						value={translations[language].login_button}
						id="change-password-button"
						onClick={(e) => toggleLogin(e)}
					/>
				</>
			) : (
				<>
					<RegEmailPrompt
						onDataChange={(e) => setEmail(e.target.value)}
						onDomainChange={(e) => setDomain(e)}
					/>
					<input
						type="submit"
						value={translations[language].change_password}
						id="change-password-button"
						onClick={checkData}
					/>
				</>
			)}
		</section>
	);
};

export default ResetPassord;
