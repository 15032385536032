import LodgmentDetailCarousel from "../carousel/LodgmentDetailCarousel";
import Meta from "antd/es/card/Meta";
import deletePost from "../../behaviour/deletePost";
import React, { useEffect, useState } from "react";

import { Avatar, Card } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
//import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../utils/languageContext";
import { translations } from "../../../utils/translations";

import "./MyLodgmentsEditor.css";
import getLocationFromCoordinates from "../../behaviour/getLocationFromCoordinates";

const getFormattedDate = (timestamp) => {
	const date = new Date(timestamp);
	const options = {
		day: "2-digit",
		month: "2-digit",
		year: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	};
	return date.toLocaleString(navigator.language, options).replace(",", " -");
};

const MyLodgmentsEditor = ({ myPosts, setEditorOpen }) => {
	const [locations, setLocations] = useState({});

	//const navigate = useNavigate();
	//const { language, toggleLanguage } = useLanguage();
	const { language } = useLanguage();

	useEffect(() => {
		const fetchLocations = async () => {
			const newLocations = {};
			for (const post of myPosts) {
				const location = await getLocationFromCoordinates(
					post.latitud,
					post.longitud
				);
				newLocations[post.id] = location;
			}
			setLocations(newLocations);
		};

		fetchLocations();
	}, [myPosts]);

	return (
		<div className="lodgment-editor-canvas">
			<div className="lodgment-editor-box">
				{myPosts.length === 0 ? (
					<p>{translations[language].lodgment_error}</p>
				) : (
					myPosts.map((post, index) => {
						return (
							<Card
								className="my-lodgments-card"
								cover={<LodgmentDetailCarousel images={post.images} />}
								key={index}
								actions={[
									<DeleteOutlined
										key="setting"
										onClick={() => deletePost(post.id)}
										style={{ fontSize: "20px" }}
									/>
								]}
							>
								<Meta
									avatar={<Avatar src={post.profile_picture} />}
									title={post.name}
									description={
										<div>
											<p style={{ fontWeight: "bolder" }}>
											{translations[language].lodgment_date}: {getFormattedDate(post.created_at)}
											</p>
											<p style={{ fontWeight: "bold" }}>
												{locations[post.id] || "Loading location..."}
											</p>
											<p>{post.brief_description}</p>
										</div>
									}
								/>
							</Card>
						);
					})
				)}
				<div className="flex justify-end space-x-2 mt-4">
					<button
						onClick={() => setEditorOpen((prev) => !prev)}
						className="bg-grey hover:bg-gray-700 text-white py-2 px-4 rounded border"
					>
						{translations[language].lodgment_cancel}
					</button>
				</div>
			</div>
		</div>
	);
};

export default MyLodgmentsEditor;
