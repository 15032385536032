import React from 'react';

export default function StudentHubMembersCard({ name, role, image }) {
  return (
    <div className="flex flex-col items-center bg-dark-grey rounded-lg shadow-md p-3">
      <img
        src={image}
        alt={`${name}`}
        className="w-24 h-24 rounded-full mb-2 object-cover"
      />
      <h3 className="text-lg font-semibold text-white">{name}</h3>
      <p className="text-sm text-white">{role}</p>
    </div>
  );
}
