// DeleteFloatingButton.js
import React, { useState } from 'react';

function DeleteFloatingButton({
    handleDeleteConfirm // Esta función se define en la página donde se instancia el botón
}) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showFinalConfirmation, setShowFinalConfirmation] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false); // Estado para el tooltip

    const handleButtonClick = () => {
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setShowFinalConfirmation(false);
    };

    const handleFirstYes = () => {
        setShowFinalConfirmation(true);
    };

    const handleFinalYes = () => {
        handleDeleteConfirm(); // Llama a la función pasada como prop para realizar la acción de eliminación
        setShowConfirmation(false);
        setShowFinalConfirmation(false);
    };

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className="relative">
            {/* Tooltip */}
            {showTooltip && (
                <div
                    id="popup-tooltip"
                    className="absolute bottom-0 right-5 bg-black text-white text-sm px-2 py-1 rounded opacity-90 z-20"
                >
                    Eliminar beneficio
                </div>
            )}

            {!showConfirmation && !showFinalConfirmation && (
                <button
                    className="fixed bottom-4 right-5 bg-grey text-white font-bold rounded-2xl w-[60px] h-[60px] flex items-center justify-center text-4xl hover:bg-grey-20 z-10"
                    onClick={handleButtonClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.50)' }}
                >
                    -
                </button>
            )}

            {showConfirmation && !showFinalConfirmation && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
                        <h2 className="text-xl mb-4">¿Desea eliminar este beneficio?</h2>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleCancel}
                                className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded"
                            >
                                No
                            </button>
                            <button
                                onClick={handleFirstYes}
                                className="bg-red-500 hover:bg-red-700 text-black py-2 px-4 rounded"
                            >
                                Sí
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showFinalConfirmation && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
                        <h2 className="text-xl mb-4">¿Está seguro de que desea eliminar este beneficio?</h2>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleCancel}
                                className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded"
                            >
                                No
                            </button>
                            <button
                                onClick={handleFinalYes}
                                className="bg-red-500 hover:bg-red-700 text-black py-2 px-4 rounded"
                            >
                                Sí, eliminar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DeleteFloatingButton;
