import React, { useState, useEffect } from "react";
import NavBar from "../../NavBar";
import UserCard from "../../UserCard"; // Import the UserCard component
import { useLanguage } from "../../../utils/languageContext"; // Importa el contexto

const ContactsPage = () => {
  const rareUniversityId = localStorage.getItem("selectedUniversityId");
  const initialUniversityId = JSON.parse(rareUniversityId);
  const [universityId, setUniversityId] = useState(initialUniversityId); // Universidad activa
  const [contacts, setContacts] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [universities, setUniversities] = useState([]); // Todas las universidades
  const [loading, setLoading] = useState(true);
  const { language, translations } = useLanguage(); // Accede al contexto de idioma y traducciones

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  // Obtener userId al montar el componente
  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      console.error("Token no encontrado en las cookies.");
      return;
    }
    const fetchUserId = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/user_id`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        if (!response.ok) {
          console.error("Error validando el usuario:", result.message);
          return;
        }
        setActiveUser(result.userId);
      } catch (error) {
        console.error("Error en la solicitud para obtener userId:", error);
      }
    };
    fetchUserId();
  }, []);

  // Obtener todas las universidades
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/contacts/${initialUniversityId}/allUniversities`);
        if (!response.ok) throw new Error("Error fetching universities");
        const allUniversities = await response.json();

        // Obtener también mi universidad
        const myUniversityResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/contacts/${initialUniversityId}/myUniversity`
        );
        if (!myUniversityResponse.ok) throw new Error("Error fetching my university");
        const myUniversity = await myUniversityResponse.json();

        // Combinar las universidades
        setUniversities([myUniversity[0], ...allUniversities]);
      } catch (error) {
        console.error("Error fetching universities:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUniversities();
  }, [initialUniversityId]);

  // Obtener contactos según la universidad seleccionada
  useEffect(() => {
    const fetchContacts = async () => {
      if (universityId != null && activeUser != null) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/contacts/${universityId}/${activeUser}/contactsByUniversity`,
            { method: "GET", headers: { "Content-Type": "application/json" } }
          );
          if (!response.ok) {
            if (response.status === 404) setContacts([]);
            throw new Error("Error fetching contacts");
          }
          const contacts = await response.json();
          setContacts(contacts);
        } catch (error) {
          console.error("Error fetching contacts:", error);
        }
      }
    };
    fetchContacts();
  }, [universityId, activeUser]);

  // Manejar cambio de universidad seleccionada
  const handleUniversityChange = (e) => {
    setUniversityId(e.target.value);
  };

  return (
    <div>
      <NavBar />

      {/* Título y toggle de universidades en una misma línea */}
      <div className="mt-10 mb-10 flex justify-center items-center gap-4">
        <h1 className="text-3xl font-bold text-gray-purple">{translations[language].contacts}</h1>

        {/* Toggle de universidades */}
        {loading ? (
          <p className="text-center">{translations[language].loading_universities}</p>
        ) : (
          <select
            value={universityId}
            onChange={handleUniversityChange}
            className="bg-gray-purple text-white px-4 py-2 rounded-lg"
          >
            {universities.map((university) => (
              <option key={university.university_id} value={university.university_id}>
                {university.name}
              </option>
            ))}
          </select>
        )}
      </div>

      {/* Mostrar los contactos si existen */}
      {contacts.length === 0 ? (
        <p className="text-center mt-4">{translations[language].no_contacts}</p>
      ) : (
        <div className="flex flex-wrap justify-center mx-auto max-w-screen-xl px-6 gap-x-10 gap-y-10 mt-8">
          {contacts.map((contact, index) => (
            <UserCard
              key={index}
              name={contact.username}
              university={contact.university_name}
              image={contact.profile_picture}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ContactsPage;