import { useEffect, useState } from "react";
import { supabase } from '../../../../supabaseClient'; // Asegúrate de importar tu cliente de Supabase
import NavBar from "../../../NavBar";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import ActivityCard from "../ActivityCard";
import TuristicSetFloatingButton from "./TuristicSetFloatingButton";
import { useLanguage } from "../../../../utils/languageContext";

export default function TuristicPage() {
  const [activities, setActivities] = useState([]);
  const navigate = useNavigate(); // Define el hook de navegación
  const { university_id} = useParams();  // Obtenemos el university_id desde la URL
  const { language, translations } = useLanguage();

  const [isVisible, setIsVisible] = useState(false);

  const TuristicFloatingButton = () => {
    return  <div className="z-100">
          <TuristicSetFloatingButton />
        </div>;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null; // Return null if the cookie is not found
};


useEffect(() => {

  const token = getCookie("token");
  if (token === null) {
    setIsVisible(false);
  }
  const body = { token };
  
  const isAdmin = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const isAdmin = await response.json();
      console.log("User " + isAdmin);
      console.log("Responese " + response.ok + response.status);


      if (!response.ok) {
        console.error(isAdmin.message)
        throw new Error("Error validando rol de administrador");
      }

      console.log(isAdmin.isAdmin);
      setIsVisible(isAdmin.isAdmin);
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  isAdmin();
}, []);

  useEffect(() => {
    // Obtener actividades turísticas desde la base de datos
    const fetchActivities = async () => {
      const { data, error } = await supabase
        .from('activity_turistic') // Asegúrate de que el nombre de la tabla esté correcto
        .select(`id,name, image: id_image (base64image), university_id`) // Solo seleccionamos los campos que necesitamos
        .eq('university_id', university_id);  

      if (error) {
        console.error("Error fetching data: ", error);
      } else {
        // Mapeamos las actividades para tener la estructura esperada
        const formattedActivities = data.map(activity => ({
          id: activity.id,
          name: activity.name,
          id_image: activity.image.base64image // Accedemos a la imagen base64
        }));

        setActivities(formattedActivities);
      }
    };

    fetchActivities();
  }, [university_id]);

  const handleCardClick = (id) => {
    navigate(`/Actividades/${id}/turisticDetail`); // Redirige a la página de detalle de la actividad
  };

  return (
    <div>
      {/* NavBar */}
      <NavBar sections={["Beneficios", "Actividades", "Hospedajes", "Conectarse", "Chats"]} />

      {/* Contenedor de actividades */}
      <div className="flex flex-col items-center mt-10">
        <h1 className="text-4xl font-bold mb-6 text-gray-purple">{translations[language].activities_turistic_title}</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {activities.map((activity, index) => (
              <ActivityCard
                key={index}
                imageUrl={activity.id_image}  // Pasamos la URL de la imagen
                title={activity.name}         // Pasamos el nombre de la actividad
                onClick={() => handleCardClick(activity.id)} // Manejador de clics
              />
            ))}
          </div>
      </div>

      <div>
            {isVisible && <TuristicFloatingButton/>}
      </div>
    </div>
  );
}

