import React, { useState } from "react";
import { useLanguage } from "../../../utils/languageContext";
import { useNavigate, useParams } from "react-router-dom";

import PasswordRegister from "../components/password/password";

import "./ResetPasswordBox.css";

const isPassword = (password, passwordConfirm) => {
	if (password == null || password.length < 8) {
		alert("La contraseña es inválida! Tiene que tener al menos 8 caracteres.");
		return false;
	}
	if (
		password !== passwordConfirm &&
		password.length > 0 &&
		passwordConfirm.length > 0
	) {
		alert("Las contraseñas no coinciden!");
		return false;
	}
	const regex = /^[\p{L}0-9@+_~!$]+$/u;
	if (!regex.test(password)) {
		alert(
			"La contraseña no es válida!\nLos caracteres permitidos son: letras, números y los siguientes caracteres especiales: _, ~, !, $, @"
		);
		return false;
	}
	return true;
};

function ResetPasswordBox() {
	const { language, translations } = useLanguage();
    const { token } = useParams();

	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");

	const [hasChanged, setHasChanged] = useState();

	const navigate = useNavigate();

	const checkData = async () => {
		if (!isPassword(password, passwordConfirm)) return;

        try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/users/change_password`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
                    body: JSON.stringify({password, token})
				}
			);

            const data = await response.json();
			if (response.ok) {
                setHasChanged(true);
			} else {
				console.error("Error al cambiar la contraseña:", data.message);
			}
		} catch (error) {
			console.error("Error en la solicitud:", error);
		}
	};

	return (
		<section className="reset-password-box">
			<h1 id="title">{translations[language].change_password}</h1>
			{hasChanged ? (
				<>
					<p>Contraseña cambiada con exito!</p>
					<input
						type="submit"
						value={translations[language].login_button}
						id="change-password-button"
						onClick={() => navigate("/Login")}
					/>
				</>
			) : (
				<>
					<PasswordRegister
						onPasswordChange={(e) => setPassword(e.target.value)}
						onPasswordConfirmChange={(e) => setPasswordConfirm(e.target.value)}
					/>
					<input
						type="submit"
						value={translations[language].change_password}
						id="change-password-button"
						onClick={checkData}
					/>
				</>
			)}
		</section>
	);
}

export default ResetPasswordBox;
