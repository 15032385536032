// BenefitFloatingButton.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import FloatingButton from './FloatingButton';

function BenefitFloatingButton() {
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const rareUniversityId = localStorage.getItem("selectedUniversityId");
    const universityId = JSON.parse(rareUniversityId);

   // Carga las categorías al cargar el componente
   useEffect(() => {
    const fetchCategories = async () => {
        try {
            const { data, error } = await supabase
                .from('category')
                .select('id, name');
            if (error) throw error;
            setCategories(data); // Guardamos las categorías en el estado
        } catch (error) {
            console.error('Error fetching categories:', error.message);
        }
    };

    fetchCategories();
}, []);

    const handleButtonClick = () => setShowForm(true);
    const handleFormClose = () => {
        setShowForm(false);
        setName('');
        setDescription('');
        setImage(null);
        setSelectedCategory('');
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Busca el category_id correspondiente al name seleccionado
        const category = categories.find(cat => cat.name === selectedCategory);
        if (!category) {
            alert('Por favor, seleccione una categoría válida.');
            return;
        }

        const category_id = category.id;

        try {
            const { data: imageData, error: imageError } = await supabase
                .from('image')
                .insert([{ base64image: image, name }])
                .select();
                
                
            if (imageError) {
                console.error("Error uploading image:", imageError);
                throw imageError;
            } else {
                console.log("Image uploaded successfully:", imageData);
            }
    
            const image_id = imageData[0].id;

            // Insertamos el beneficio en la tabla benefit
            const { data: benefitData, error: benefitError } = await supabase
                .from('benefit')
                .insert([{ description, category_id, name }])
                .select();
            if (benefitError) throw benefitError;

            const benefit_id = benefitData[0].id;

             // Insertamos la relación en la tabla imagebenefit
            const { error: imageBenefitError } = await supabase
                .from('imagebenefit')
                .insert([{ benefit_id, image_id }]);
            if (imageBenefitError) throw imageBenefitError;

            //Insertamos la relacion en la tabla universitybenefit
            const { error: universitybenefitError } = await supabase
                .from('universitybenefit')
                .insert([{ benefit_id, university_id: universityId }]);
            if (universitybenefitError) throw universitybenefitError;

            // Aquí puedes insertar en universitybenefit si tienes los datos
            alert('Beneficio agregado exitosamente!');
            handleFormClose();
        } catch (error) {
            console.error('Error al agregar el beneficio:', error.message);
            alert('Hubo un error al agregar el beneficio. Por favor, intente nuevamente.');
        }
    };

    return (
        <FloatingButton
            showForm={showForm}
            handleButtonClick={handleButtonClick}
            handleFormClose={handleFormClose}
            handleSubmit={handleSubmit}
            handleImageUpload={handleImageUpload}
            setName={setName}
            setDescription={setDescription}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
        />
    );
}

export default BenefitFloatingButton;
