import React from "react";
import "./ConfirmPasswordPrompt.css";
import { useLanguage } from "../../../../../utils/languageContext";


const PasswordRegister = ({ onPasswordConfirmChange }) => {
  const togglePass = (elementId, passButtonId) => {
    const elem = document.getElementById(elementId);
    const button = document.getElementById(passButtonId);
    if (elem.type === "password") {
      elem.type = "text";
      button.className = "showPassButtonOff";
    } else {
      elem.type = "password";
      button.className = "showPassButtonOn";
    }
  };

  const { language, translations } = useLanguage();


  return (
    <div className="passPromptConfirm">
      <input
        className="font-playWrite password-input"
        id="passConfirm"
        type="password"
        placeholder={translations[language].confirm_password}
        onChange={(e) => onPasswordConfirmChange(e)}
      />
      <button
        className="showPassButtonOn"
        id="confirmButton"
        onClick={() => togglePass("passConfirm", "confirmButton")}
      />
    </div>
  );
};

export default PasswordRegister;
