import React from "react";
import { Card, Carousel, Avatar, Typography, Tooltip } from "antd";
import {
	LeftOutlined,
	RightOutlined
} from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const CardCarousel = ({
	images = [],
	title,
	userId,
	description,
	avatarUrl,
	onClick,
	setProfileOpen,
	setUserPostId
}) => {

	const handleCardClick = (e) => {
		// Prevent click event from propagating if it originated from the avatar, carousel arrows, or dots
		if (
			!e.target.closest(".ant-avatar") &&
			!e.target.closest(".slick-arrow") &&
			!e.target.closest(".slick-dots")
		) {
			onClick && onClick(e);
		}
	};

	const handleAvatarClick = (e) => {
		e.stopPropagation();
		setProfileOpen(true);
		setUserPostId(userId);
	};

	// Custom arrow components
	const PrevArrow = (props) => {
		const { className, onClick } = props;
		return <LeftOutlined className={className} onClick={onClick} />;
	};

	const NextArrow = (props) => {
		const { className, onClick } = props;
		return <RightOutlined className={className} onClick={onClick} />;
	};

	return (
		<Card
			style={{
				width: 400,
				maxWidth: "100%",
				borderRadius: "16px 16px 8px 8px",
				overflow: "hidden",
				transition: "box-shadow 0.3s ease-in-out",
				cursor: "pointer",
			}}
			hoverable
			onClick={handleCardClick}
			cover={
				images.length > 0 ? (
					<Carousel
						arrows
						prevArrow={<PrevArrow />}
						nextArrow={<NextArrow />}
						style={{ borderRadius: "16px 16px 0 0" }}
					>
						{images.map((image, index) => (
							<div key={index}>
								<img
									alt={`Slide ${index + 1}`}
									src={image}
									style={{ width: "100%", height: 250, objectFit: "cover" }}
								/>
							</div>
						))}
					</Carousel>
				) : (
					<div
						style={{
							width: "100%",
							height: 250,
							backgroundColor: "#f0f0f0",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "16px 16px 0 0",
						}}
					>
						No Image Available
					</div>
				)
			}
		>
			<Card.Meta
				avatar={
					avatarUrl ? (
            <Tooltip title="View Profile">
              <Avatar
                src={avatarUrl}
                className="ant-avatar"
                onClick={handleAvatarClick}
                style={{ cursor: "pointer", width: "50px", height: "50px" }}
              />
            </Tooltip>
					) : null
				}
				title={
					title && (
						<Title level={4} style={{ marginBottom: 0 }}>
							{title}
						</Title>
					)
				}
				description={
					description && (
						<Paragraph
							ellipsis={{
								rows: 2,
								expandable: true,
								symbol: 'more',
								onExpand: (e) => e.stopPropagation()
							}}
						>
							{description}
						</Paragraph>
					)
				}
			/>
		</Card>
	);
};

export default CardCarousel;
