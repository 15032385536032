import React from "react";
import "./Register.css";
import RegisterBox from "../../auth/register/RegisterBox";
import { useNavigate } from "react-router-dom";
import OuterNavBar from "../../OuterNavBar";
import handleHome from "../../behaviour/handleHome";
import handleAutoLogin from "../../behaviour/handleAutoLogin";
import { useLanguage } from "../../../utils/languageContext";

const RegisterPage = () => {
  const navigate = useNavigate(); // Para la navegación
  const { language, translations } = useLanguage();

  const handleLogin = () => {
    if (handleAutoLogin()) {
      handleHome(navigate);
    }
    navigate("/Login");
  };

  return (
    <div className="Register-page">
      {/* Incluye la barra de navegación */}
      <OuterNavBar
        rightUpperButton={translations[language].login_button}
        rightUpperButtonFunction={handleLogin}
      />
      <RegisterBox />
    </div>
  );
};

export default RegisterPage;
