import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Drawer, Button, Input, Skeleton } from 'antd';
import { Scrollbars } from 'rc-scrollbars';
import { ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import { getCookie } from '../../behaviour/getCookies';
import axios from 'axios';
import { format, isSameDay } from 'date-fns';
import Pusher from 'pusher-js';
import ContactList from '../../chat/ContactList';
import DateDivider from '../../chat/DateDivider';
import ReceivedMessage from '../../chat/RecivedMessage';
import SentMessage from '../../chat/SentMessage';
import UnreadMessagesDivider from '../../chat/UnreadMessageDivider';

import NavBar from '../../NavBar';

/*const mockContacts = [
    { id: 1, name: 'Alice', avatar: 'https://xsgames.co/randomusers/avatar.php?g=female', lastMessage: 'Hey there!', unreadCount: 2 },
    { id: 2, name: 'Bob', avatar: 'https://xsgames.co/randomusers/avatar.php?g=male', lastMessage: 'How\'s it going?', unreadCount: 0 },
    { id: 3, name: 'Charlie', avatar: 'https://xsgames.co/randomusers/avatar.php?g=male', lastMessage: 'See you later!', unreadCount: 1 },
    // Add more mock contacts to demonstrate scrolling
    ...Array.from({ length: 20 }, (_, i) => ({
      id: i + 4,
      name: `User ${i + 4}`,
      avatar: `https://xsgames.co/randomusers/avatar.php?g=${i % 2 === 0 ? 'male' : 'female'}`,
      lastMessage: `Message ${i + 4}`,
      unreadCount: Math.floor(Math.random() * 3)
    }))
  ];
  
  const mockMessages = [
    { id: 1, type: 'date', content: 'May 15, 2023' },
    { id: 2, type: 'received', content: 'Hey there! How are you doing today?', username: 'Alice', time: '10:00 AM' },
    { id: 3, type: 'sent', content: 'Hi Alice! I\'m doing great, thanks for asking. How about you?', username: 'You', time: '10:05 AM', isRead: true },
    { id: 4, type: 'received', content: 'I\'m good too! Just working on some React components.', username: 'Alice', time: '10:10 AM' },
    { id: 5, type: 'date', content: 'May 16, 2023' },
    { id: 6, type: 'sent', content: 'That\'s awesome! I\'m actually working on a chat system right now. It\'s coming along nicely.', username: 'You', time: '9:30 AM', isRead: true },
    { id: 7, type: 'unread' },
    { id: 8, type: 'received', content: 'Wow, that sounds interesting! Can you tell me more about it?', username: 'Alice', time: '10:15 AM' },
    { id: 9, type: 'sent', content: 'I\'m implementing various message types and working on the UI. It\'s been a great learning experience.', username: 'You', time: '10:20 AM', isRead: false },
  ];*/
  

  
  const ChatPage = () => {
    const [selectedContact, setSelectedContact] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    //const [userId, setUserId] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [messageInput, setMessageInput] = useState('');
    const [searchQuery, setSearchQuery] = useState(''); // Estado para el buscador
    const [chatData, setChatData] = useState([]);
    const [chatDataMapped, setChatDataMapped] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]); // Contactos filtrados
    const [isLoading, setIsLoading] = useState(true); // Nuevo estado para mostrar el skeleton
    const [messages, setMessages] = useState([]);
    const [mappedMessages, setMappedMessages] = useState([]);
    const [username, setUsername] = useState(''); // Cambiar según el usuario actual

    const SENDER_USERNAME = 'You';

    useEffect(() => {

      const token = getCookie("token");
      if (token === null) {
          throw new Error("Error buscando token");
      }
      const body = { token };
      
      const username = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/username`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
  
          const data = await response.json();
  
          if (!response.ok) {
            console.error(data.message)
            throw new Error("Error buscando el nombre de usuario");
          }
  
          setUsername(data.username)
        } catch (error) {
          console.error("Error en la solicitud:", error);
        }
      };
  
      username();
    }, []);
  
    useEffect(() => {
      const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      checkMobile();
      window.addEventListener('resize', checkMobile);
  
      return () => window.removeEventListener('resize', checkMobile);
    }, []);

    /*useEffect(() => {
      const token = getCookie("token");
      if (token === null) {
        throw new Error("Error buscando token");
      }
      const body = { token };
  
      const getUserId = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/user_id`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
  
          const data = await response.json();
  
          if (!response.ok) {
            console.error(data.message);
            throw new Error("Error buscando chats");
          }

          setUserId(data.userId)

        } catch (error) {
          console.error("Error en la solicitud:", error);
        }
      };
  
      getUserId();
    }, []); */

  
    useEffect(() => {
      const token = getCookie("token");
      if (token === null) {
        throw new Error("Error buscando token");
      }
      const body = { token };
  
      const getChats = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/messages/getChats`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
  
          const data = await response.json();
  
          if (!response.ok) {
            console.error(data.message);
            throw new Error("Error buscando chats");
          }
  
          console.log("data", data);

        setChatData(data);

        } catch (error) {
          console.error("Error en la solicitud:", error);
        } finally {
          setIsLoading(false); // Se termina de cargar
        }
      };
  
      getChats();
    }, []);

    useEffect(() => {
      
      if (selectedContact !== null) {
      const body = { chatId: selectedContact.chatId };
  
      const getMessages = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/messages/getMessages`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
  
          const data = await response.json();
  
          if (!response.ok) {
            console.error(data.message);
            throw new Error("Error buscando chats");
          }
  
          console.log("data message", data);

          setMessages(data);

        } catch (error) {
          console.error("Error en la solicitud:", error);
        } finally {
          setIsLoading(false); // Se termina de cargar
        }
      };
  
      getMessages();
    }
    }, [selectedContact]);

    useEffect(() => {

      const pusher = new Pusher('6425bcb0832cd871505e', {
        cluster: 'sa1',
        encrypted: true, // Esto asegura que la conexión sea segura
      });

      const channels = {};

      chatData.forEach(chat => {
        const channel = pusher.subscribe(`chat-channel-${chat.chat_id}`);
        channels[chat.chat_id] = channel;

        channel.bind("new-message", (data) => {
            if (selectedContact.chat_id !== chat.chat_id) {
                chat.unread_count += 1;
            }
        });
      });  

      return () => {
        chatData.forEach(chat => {
            pusher.unsubscribe(`chat-channel-${chat.chat_id}`);
        });
      };
  
    }, [chatData, selectedContact]);

    useEffect(() => {
        if(selectedContact !== null) {
          console.log("selected contact", selectedContact)
        // Conectar a Pusher
          const pusher = new Pusher('6425bcb0832cd871505e', {
              cluster: 'sa1',
              encrypted: true, // Esto asegura que la conexión sea segura
            });
    
          const channel = pusher.subscribe(`chat-channel-${selectedContact.chatId}`);
          channel.bind('new-message', (data) => {
            setMessages((prevMessages) => [...prevMessages, data]);
            console.log("Nuevo mensaje",data)
          });
    
        // Cleanup
          return () => {
            pusher.unsubscribe(`chat-channel-${selectedContact.chatId}`);
          };
        }
      }, [selectedContact]);
  
    useEffect(() => {
      const mappedChatData = chatData.map((chat, index) => ({
        id: index,
        chatId: chat.chat_id,
        userId: chat.user_id,
        name: chat.other_username,
        avatar: chat.other_profile_picture,
        lastMessage: chat.latest_message,
        unreadCount: chat.unread_count,
      }));
  
      console.log("data mapped", mappedChatData);
      setChatDataMapped(mappedChatData);
    }, [chatData]);

    useEffect(() => {
      
        const result = [];
        let lastDate = null;
        let unreadInserted = false;
      
        messages.forEach((message, index) => {
          const createdAt = new Date(message.created_at);
          const formattedDate = format(createdAt, 'MMM dd, yyyy');
          const formattedTime = format(createdAt, 'hh:mm a');
      
          // Insertar un tipo 'date' si es el primer mensaje del día
          if (!lastDate || !isSameDay(new Date(lastDate), createdAt)) {
            result.push({
              id: result.length + 1,
              type: 'date',
              content: formattedDate,
            });
            lastDate = createdAt;
          }
      
          // Determinar el tipo de mensaje (sent o received)
          const type = message.user_id !== selectedContact.userId ? 'sent' : 'received';
          const username = type === 'sent' ? SENDER_USERNAME : selectedContact.username;
      
          // Insertar elemento 'unread' antes del primer mensaje 'received' no leído
          if (!unreadInserted && type === 'received' && !message.read) {
            result.push({
              id: result.length + 1,
              type: 'unread',
            });
            unreadInserted = true;
          }
      
          // Insertar el mensaje formateado
          result.push({
            id: result.length + 1,
            type,
            content: message.message,
            username,
            time: formattedTime,
            ...(type === 'sent' && { isRead: message.read }), // Solo incluir isRead si el mensaje es enviado
          });
        });
      console.log("data mapped", result);
      setMappedMessages(result);
    }, [messages, selectedContact]);
  
    useEffect(() => {
      setFilteredContacts(chatDataMapped);
    }, [chatDataMapped]);
  
    const handleSearchChange = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchQuery(query);
      setFilteredContacts(
        chatDataMapped.filter((contact) =>
          contact.name.toLowerCase().includes(query)
        )
      );
    };

    /*const markMessageAsRead = (chatId, userId) => {
      pusher.trigger(`private-${chatId}`, 'message-read', {
        chatId,
        userId,
      });
    };*/

    const markMessageAsRead = async (chatId, userId) => {

      console.log("Chat ID:", chatId, "User ID:", userId);
  
      await axios.post(`${process.env.REACT_APP_API_URL}/messages/read`, {
        chatId: chatId,
        userId: userId,  
      });
  
    };
  
    const handleSelectContact = (contact) => {
      contact.unreadCount = 0
      markMessageAsRead(contact.chatId, contact.userId);
      setSelectedContact(contact);
      if (isMobile) {
        setDrawerVisible(true);
      }
    };
  
    const closeDrawer = () => {
      setDrawerVisible(false);
    };
  
    const handleSendMessage = async (e) => {
      if (messageInput.trim() === '') return;

      const token = getCookie("token");
      if (token === null) {
        throw new Error("Error buscando token");
      }

      const currentDate = new Date();

      //e.preventDefault();
    
      // Envía el mensaje al servidor Express
      await axios.post(`${process.env.REACT_APP_API_URL}/messages/chat`, {
        username,
        message: messageInput, 
        chatId: selectedContact.chatId,
        time: currentDate,
        token
      });

      setMessageInput('');
    };
  
    const handleInputKeyPress = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault(); // Prevenir salto de línea
        handleSendMessage();
      }
    };
  
    const renderMessages = () => (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Scrollbars style={{ flex: 1 }}>
          <div style={{ padding: '0 16px' }}>
            {mappedMessages.map((message) => {
              switch (message.type) {
                case 'date':
                  return <DateDivider key={message.id} date={message.content} />;
                case 'received':
                  return (
                    <ReceivedMessage
                      key={message.id}
                      message={message.content}
                      username={message.username}
                      time={message.time}
                    />
                  );
                case 'sent':
                  return (
                    <SentMessage
                      key={message.id}
                      message={message.content}
                      username={message.username}
                      time={message.time}
                      isRead={message.isRead}
                    />
                  );
                case 'unread':
                  return <UnreadMessagesDivider key={message.id} />;
                default:
                  return null;
              }
            })}
          </div>
        </Scrollbars>
        <div
          style={{
            padding: '8px 16px',
            borderTop: '1px solid #f0f0f0',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Input
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyPress={handleInputKeyPress}
            placeholder="Escribe un mensaje..."
            style={{ flex: 1, marginRight: '8px' }}
          />
          <Button icon={<SendOutlined />} type="primary" onClick={handleSendMessage} />
        </div>
      </div>
    );
  
    return (
      <div className="flex flex-col min-h-screen">
        {/* NavBar */}
        <NavBar
          sections={['Beneficios', 'Actividades', 'Hospedajes', 'Conectarse', 'Chats']}
        />
  
        <Card
          bodyStyle={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
          style={{
            width: '100%',
            height: 'calc(100vh - 64px)',
            borderRadius: isMobile ? 0 : '8px',
          }}
        >
          {isMobile ? (
            <>
              {!drawerVisible ? (
                <>
                  <Input
                    placeholder="Buscar contacto..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '16px', padding: '8px' }}
                  />
                  {isLoading ? (
                    <Skeleton active paragraph={{ rows: 5 }} />
                  ) : (
                    <ContactList
                      contacts={filteredContacts}
                      onSelectContact={handleSelectContact}
                      selectedContactId={selectedContact?.id}
                    />
                  )}
                </>
              ) : (
                <Drawer
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        icon={<ArrowLeftOutlined />}
                        onClick={closeDrawer}
                        style={{ marginRight: 16 }}
                      />
                      {selectedContact?.name}
                    </div>
                  }
                  placement="right"
                  closable={false}
                  onClose={closeDrawer}
                  visible={drawerVisible}
                  width="100%"
                  bodyStyle={{ padding: 0 }}
                >
                  {renderMessages()}
                </Drawer>
              )}
            </>
          ) : (
            <Row gutter={16} style={{ height: '100%' }}>
              <Col span={8} style={{ height: '100%' }}>
                <Input
                  placeholder="Buscar contacto..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ marginBottom: '16px', padding: '8px' }}
                />
                {isLoading ? (
                  <Skeleton active paragraph={{ rows: 5 }} />
                ) : (
                  <ContactList
                    contacts={filteredContacts}
                    onSelectContact={handleSelectContact}
                    selectedContactId={selectedContact?.id}
                  />
                )}
              </Col>
              <Col span={16} style={{ height: '100%' }}>
                {selectedContact ? (
                  renderMessages()
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      color: '#aaa',
                    }}
                  >
                    Selecciona un contacto para empezar a chatear
                  </div>
                )}
              </Col>
            </Row>
          )}
        </Card>
      </div>
    );
  };
  
  
  export default ChatPage;
  