import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importa useNavigate

import BenefitFloatingButton from "../../BenefitFloatingButton";
import CategoryButton from "../../CategoryButton";
import BenefitSearch from "../../BenefitSearch";
import BenefitCard from "../../BenefitCard";
import Carousel from "../../Carousel";
import NavBar from "../../NavBar";
//import { getCookie } from "../../behaviour/getCookies";

function HomePage() {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null); // Estado para saber qué botón está subrayado
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [benefits, setBenefits] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate(); //inicializa useNavigate

  const rareUniversityId = localStorage.getItem("selectedUniversityId");
  const universityId = JSON.parse(rareUniversityId);

  const FloatingButton = () => {
    return  <div className="z-100">
              <BenefitFloatingButton />
            </div>;
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null; // Return null if the cookie is not found
  };

  useEffect(() => {

    const token = getCookie("token");
    if (token === null) {
      setIsVisible(false);
    }
    const body = { token };
    
    const isAdmin = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/admin`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );

        const isAdmin = await response.json();


        if (!response.ok) {
          console.error(isAdmin.message)
          throw new Error("Error validando rol de administrador");
        }

        setIsVisible(isAdmin.isAdmin);
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    };

    isAdmin();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/benefits/${universityId}/categories`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching categories");
        }

        const categories = await response.json();
        setCategories(categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [universityId]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/benefits/${universityId}/featured_images`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching categories");
        }

        const featured_images = await response.json();

        setImages(featured_images);
      } catch (error) {
        console.error("Error fetching featured images:", error);
      }
    };

    fetchImages();
  }, [universityId]);

  useEffect(() => {
    const fetchBenefitsByCategory = async () => {
      if (!selectedCategory) return; // Si no hay categoría seleccionada, no hacer nada

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/benefits/${universityId}/${selectedCategory}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching benefits");
        }

        const benefits = await response.json();

        // Actualizar el estado con los beneficios filtrados
        setBenefits((prevBenefits) => ({
          ...prevBenefits,
          [selectedCategory]: benefits,
        }));
      } catch (error) {
        console.error("Error fetching benefits:", error);
      }
    };

    fetchBenefitsByCategory();
  }, [selectedCategory, universityId]);

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName); // Actualiza la categoría activa
    setSelectedCategory(categoryName); // Actualiza la categoría seleccionada para filtrar los beneficios
  };

  const handleCardClick = (benefitId, category) => {
    navigate(`/Beneficio/${benefitId}?benefitId=${benefitId}`); // Redirige con benefitId y category
  };

  return (
    <div>
      <div className="relative">
        {/* NavBar */}
        <div>
          <NavBar/>
        </div>

        {/* Benefit Search positioned above the carousel */}
        <div className="absolute top-15 left-1/2 transform -translate-x-1/2 z-3">
          <BenefitSearch />
        </div>

        {/* Carousel */}
        <div className="z-1">
          <Carousel images={images} />
        </div>

        {/* Category Buttons */}
        <div className="absolute top-1/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center space-x-[43px] z-2">
          {categories.length > 0 ? (
            categories.map((category, index) => (
              <CategoryButton
                key={index}
                categoryName={category.name}
                icon={category.base64image}
                isActive={activeCategory === category.name} // Compara si esta categoría está activa
                onClick={() => handleCategoryClick(category.name)} // Asigna la función para manejar clics
              />
            ))
          ) : (
            <p className="col-span-3 text-center">No categories available</p>
          )}
        </div>
      </div>

      {/* Benefit Cards */}
      <div className="relative mt-40 flex flex-wrap justify-center space-x-4 z-4">
        {selectedCategory &&
          benefits[selectedCategory] &&
          benefits[selectedCategory].map((benefit, index) => (
            <BenefitCard
              key={index}
              imageUrl={benefit.images[0]}
              alt={benefit.name}
              title={benefit.name}
              onClick={() => handleCardClick(benefit.id, selectedCategory)}
            />
          ))}
      </div>
      {isVisible && <FloatingButton />}
    </div>
  );
}

export default HomePage;
