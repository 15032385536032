// translations.js
export const translations = {
    en: {

        // Cadenas de texto para OuterBar
        contact: 'Contact Us',
        mission: 'Our Mission',
        login_button: 'Login',
        change_password: 'Change password',
        register_button: 'Register',

        // Cadenas de texto para la Landing Page
        greeting: 'Welcome to StudentHub!',
        platformDescription: 'The platform to connect with students and discover the benefits your University offers.',
        benefitsTitle: 'BENEFITS',
        benefitsDescription: 'Discover all the benefits your University offers.',
        activitiesTitle: 'ACTIVITIES',
        activitiesDescription: 'Learn about activities you can participate in as a student.',
        connectPeopleTitle: 'CONNECT WITH PEOPLE',
        connectPeopleDescription: 'Connect with other students near you.',
        accommodationsTitle: 'LODGMENTS',
        accommodationsDescription: 'Find lodgment or people to share costs if you\'re an exchange student.',

        //Cadenas de texto para la NavBar
        section_benefits: 'Benefits',
        section_activities: 'Activities',
        section_accommodations: 'Lodgments',
        section_get_in_touch: 'Get in touch',
        section_chats: 'Chats',

        // Cadenas de texto para SearchBar
        search: 'Search',

        //Cadenas de texto para seccion actividades
        activities_title: 'Explore and find the perfect activity for you!',
        activities_description: 'In this section, you will find activities for all interests, from tourist experiences to social and academic events. Discover options to enjoy, learn, and connect with new opportunities!',
        activities_turistic_category: 'Turistic',
        activities_social_category: 'Social',
        activities_academic_category: 'Academic',
        activities_turistic_title: 'Turistic Activities',
        activities_social_title: 'Social Activities',
        activities_academic_title: 'Academic Activities',

        //Cadenas de texto para seccion de Soporte Técnico
        support_title: 'Contact Us',
        support_description: 'You can contact us anytime for anything you need. We will try to respond as soon as possible!',
        support_send_button: 'Send',
        support_msj_name: 'Name',
        support_msj_content: 'Message',
        support_msj_warning: 'Please, complete the field',
        support_information: 'Our contact details',

        // Cadenas de texto para Register y Login
        username: 'Username',
        password: 'Password',
        confirm_password: 'Confirm password',

        // Cadenas de texto para Nuestra Mision
        our_mission: 'Our Mission',
        issue: 'The Issue',
        issue_detail: 'Students face difficulties accessing key information about benefits, activities, and accommodation options. This creates disconnection and additional challenges, especially for those moving to new cities.',
        solution: 'The solution',
        solution_detail: 'We created a platform that connects students and facilitates access to university benefits, activities, and accommodation options. Our focus is on fostering community and enhancing the student experience.',
        our_members: 'Our Members',

        // Cadenas de texto para Contactarse
        contacts: 'Your contacts from: ',
        select_uni: 'Select a university',
        no_contacts: 'No contacts available for this university',


        //Cadenas de texto para Hosedaje
        input_direction: 'Enter an address',
        add_lodgment: 'Add a lodgment',
        lodgment_title: 'Title',
        lodgment_description: 'Description',
        lodgment_photos: 'Images',
        lodgment_cancel: 'Cancel',
        lodgment_publish: 'Publish',
        lodgment_error: 'You have no lodgments published yet...',
        lodgment_date: 'Created at',

        // Quill
        post_content: 'Post content',

        //Cadenas de texto para User
        edit_photo: 'Edit photo',
        user_name: 'Username: ',
        save_user_name: 'Save username',
        save_new_password: 'Save new password',
        enter_actual_password: 'Enter actual password',
        enter_new_password: 'Enter new password',
        confirm_new_password: 'Confirm new password',
        add_other_uni_email: 'Add other university email',
        other_uni_email: 'Other university email',
        confirm: 'Confirm',
        delete_other_uni_email: 'Delete other university email',
        to_delete_email: 'To delete email',
        delete: 'Delete',
        close_session: 'Close session'
        
        


    },
    es: {

        // Cadenas de texto para OuterBar
        contact: 'Contactanos',
        mission: 'Nuestra Misión',
        login_button: 'Ingresar',
        change_password: 'Cambiar contraseña',
        register_button: 'Registrarse',

        // Cadenas de texto para la Landing Page
        greeting: '¡Bienvenidos a StudentHub!',
        platformDescription: 'La plataforma para conectar con estudiantes y conocer los beneficios que ofrece tu Universidad',
        benefitsTitle: 'BENEFICIOS',
        benefitsDescription: 'Descubre todos los beneficios que ofrece tu Universidad.',
        activitiesTitle: 'ACTIVIDADES',
        activitiesDescription: 'Investiga acerca de las actividades que puedes realizar como estudiante.',
        connectPeopleTitle: 'CONECTAR CON PERSONAS',
        connectPeopleDescription: 'Conéctate con otros estudiantes que estén cerca tuyo.',
        accommodationsTitle: 'ALOJAMIENTOS',
        accommodationsDescription: 'Encuentra alojamiento o personas con quienes compartir gastos si eres estudiante de intercambio.',

        //Cadenas de texto para la NavBar
        section_benefits: 'Beneficios',
        section_activities: 'Actividades',
        section_accommodations: 'Hospedajes',
        section_get_in_touch: 'Contactarse',
        section_chats: 'Chats',

        // Cadenas de texto para SearchBar
        search: 'Buscar',

        //Cadenas de texto para seccion actividades
        activities_title: ' ¡Explora y encuentra la actividad perfecta para ti!',
        activities_description: 'En esta sección encontrarás actividades para todos los intereses, desde experiencias turísticas hasta eventos sociales y académicos. Descubre opciones para disfrutar, aprender y conectarte con nuevas oportunidades!',
        activities_turistic_category: 'Turísticas',
        activities_social_category: 'Sociales',
        activities_academic_category: 'Académicas',
        activities_turistic_title: 'Actividades Turísticas',
        activities_social_title: 'Actividades Sociales',
        activities_academic_title: 'Actividades Académicas',

        //Cadenas de texto para seccion de Soporte tecnico
        support_title: 'Contactanos',
        support_description: 'Puedes contactarnos para lo que desees en cualquier momento. Trataremos de responder lo mas pronto posible!',
        support_send_button: 'Enviar',
        support_msj_name: 'Nombre',
        support_msj_content: 'Mensaje',
        support_msj_warning: 'Por favor, completa el campo',
        support_information: 'Nuestros detalles de contacto',

        // Cadenas de texto para Register y Login
        username: 'Nombre de usuario',
        password: 'Contraseña',
        confirm_password: 'Confirma contraseña',

        // Cadenas de texto para Nuestra Mision
        our_mission: 'Nuestra Misión',
        issue: 'La problemática',
        issue_detail: 'Los estudiantes enfrentan dificultades para acceder a información clave sobre beneficios, actividades y opciones de alojamiento. Esto genera desconexión y retos adicionales, especialmente para quienes se mudan a nuevas ciudades.',
        solution: 'La solución',
        solution_detail: ' Creamos una plataforma que conecta estudiantes y facilita el acceso a beneficios universitarios, actividades y opciones de alojamiento. Nuestro enfoque está en fomentar comunidad y mejorar la experiencia estudiantil.',
        our_members: 'Nuestros Miembros',

        // Cadenas de texto para Contactarse
        contacts: 'Tus contactos de la universidad: ',
        select_uni: 'Selecciona una universidad',
        no_contacts: 'No hay contactos disponibles para esta universidad',
        

        //Cadenas de texto para Hosedaje
        input_direction: 'Ingresa una dirección',
        add_lodgment: 'Ingresa un hospedaje',
        lodgment_title: 'Título',
        lodgment_description: 'Descripción',
        lodgment_photos: 'Imágenes',
        lodgment_cancel: 'Cancelar',
        lodgment_publish: 'Publicar',
        lodgment_error: 'No tienes ningún hospedaje publicado todavía...',
        lodgment_date: 'Creado el',

        // Quill
        post_content: 'Contenido del posteo',

        //Cadenas de texto para User
        edit_photo: 'Editar foto',
        user_name: 'Nombre de usuario:',
        save_user_name: 'Guardar nombre de usuario',
        save_new_password: 'Guardar nueva contraseña',
        enter_actual_password: 'Ingresa tu contraseña actual',
        enter_new_password: 'Escribe tu nueva contraseña',
        confirm_new_password: 'Confirma tu nueva contraseña',
        add_other_uni_email: 'Agregar correo de otra universidad',
        other_uni_email: 'Correo de otra universidad',
        confirm: 'Confirmar',
        delete_other_uni_email: 'Eliminar correo de otra universidad',
        to_delete_email: 'Correo a eliminar',
        delete: 'Eliminar',
        close_session: 'Cerrar sesión'

    },
  };
  