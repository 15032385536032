import React, { useState } from "react";
import "./Login.css";
import LoginBox from "../../auth/login/LoginBox";
import OuterNavBar from "../../OuterNavBar";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../utils/languageContext";
import ResetPassord from "../../auth/resetPassword/ResetPassword";

const Login = () => {
	const navigate = useNavigate(); // Para la navegación
	const { language, translations } = useLanguage();

	const [isLogin, setIsLogin] = useState(true);

	const handleRegister = () => {
		navigate("/Register");
	};

	return (
		<div className="Login-page">
			<OuterNavBar
				rightUpperButton={translations[language].register_button}
				rightUpperButtonFunction={handleRegister}
			/>
			{isLogin ? <LoginBox toggleLogin={setIsLogin} /> : <ResetPassord toggleLogin={setIsLogin} /> }
		</div>
	);
};

export default Login;
