import {
  CNavbar,
  CContainer,
  CNavbarBrand,
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
  CButton,
} from "@coreui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLanguage } from "../utils/languageContext"; // Importa el hook
import { translations } from "../utils/translations"; 

export default function OuterNavBar({
  rightUpperButton,
  rightUpperButtonFunction,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const { language, toggleLanguage } = useLanguage(); // Accede al contexto del idioma

  useEffect(() => {
    if (location.pathname === "/NuestroContacto") {
      setActiveSection("contact");
    } else if (location.pathname === "/NuestraMision") {
      setActiveSection("history");
    } else {
      setActiveSection("");
    }
  }, [location.pathname]);

  const handleLanding = () => {
    navigate("/");
  };

  const handleContact = () => {
    navigate("/NuestroContacto");
  };

  const handleHistory = () => {
    navigate("/NuestraMision");
  };

  return (
    <div className="bg-grey">
      <CNavbar expand="lg">
        <CContainer fluid className="position-relative">
          {/* Logo */}
          <CNavbarBrand
            className="text-white text-3xl"
            onClick={handleLanding}
            style={{ cursor: "pointer" }}
          >
            StudentHub
          </CNavbarBrand>

          {/* Toggle Menu */}
          <CNavbarToggler
            aria-label="Toggle navigation"
            aria-expanded={visible}
            onClick={() => setVisible(!visible)}
          />

          {/* Secciones centradas */}
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav
              as="nav"
              className="position-absolute start-50 translate-middle-x d-flex"
            >
              <button
                className={`mx-3 text-xl ${
                  activeSection === "contact" ? "underline text-white" : "text-white"
                }`}
                onClick={handleContact}
              >
                <div>{translations[language].contact}</div>
              </button>

              <button
                className={`mx-3 text-xl ${
                  activeSection === "history" ? "underline text-white" : "text-white"
                }`}
                onClick={handleHistory}
              >
                <div>{translations[language].mission}</div>
              </button>
            </CNavbarNav>
          </CCollapse>

          {/* Botón derecho superior */}
          <CButton
            className="me-md-2 text-white d-flex justify-content-center align-items-center bg-white"
            type="button"
            shape="rounded-pill"
            onClick={rightUpperButtonFunction}
            style={{
              width: "160px",
              height: "50px",
            }}
          >
            <div className="text-grey text-2xl">{rightUpperButton}</div>
          </CButton>

          {/* Botón EN/ES */}
          <div className="d-flex align-items-center">
            <div
              onClick={toggleLanguage}
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                color: "white",
                textDecoration: language === "es" ? "underline" : "none",
                marginRight: "5px",
              }}
            >
              ES
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              /
            </div>
            <div
              onClick={toggleLanguage}
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                color: "white",
                textDecoration: language === "en" ? "underline" : "none",
                marginLeft: "5px",
              }}
            >
              EN
            </div>
          </div>
        </CContainer>
      </CNavbar>
    </div>
  );
}