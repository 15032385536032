import "./LodgmentAdderButton.css";
import React, { useState, useEffect } from "react";

const LodgmentAdderButton = ({ setAdderOpen, setEditorOpen }) => {
  const [hidden, setHidden] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const preloadImages = (images) => {
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  preloadImages([
    '../../images/icons/options.svg',
    '../../images/icons/options.svg',
    '../../images/icons/options.svg'
  ]);

  useEffect(() => {
    if (hidden) {
      setShowButtons(true);
    } else {
      const timer = setTimeout(() => setShowButtons(false), 300);
      return () => clearTimeout(timer);
    }
  }, [hidden]);

  return (
    <div
      className="lodgment-adder-button-box"
      onMouseEnter={() => setHidden(true)}
      onMouseLeave={() => setHidden(false)}
    >
      {showButtons &&
        <div className={`button-container ${hidden ? 'show' : 'hide'}`}>
          <button
            className="lodgment-adder-button"
            id="my-lodgments"
            onClick={() => setEditorOpen((prev) => !prev)}
          />
          <button
            className="lodgment-adder-button"
            id="add-lodgments"
            onClick={() => setAdderOpen((prev) => !prev)}
          />
        </div>
      }
      <button
        className="lodgment-adder-button"
        id="hover-button"
      />
    </div>
  );
};

export default LodgmentAdderButton;
