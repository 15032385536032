const isEmail = (email) => {
	if (email == null || email.length < 6) return false;

	if (email.length > 320) {
		alert("El mail es muy largo!");
		return false;
	} else if ((email.match(/@/g) || []).length > 1) {
		alert("Ingresa un mail válido!");
		return false;
	}

	const splited = email.split("@");

	const localPartRegex = /^[\p{L}\p{N}+_~!$]+$/u;
	const domainPartRegex = /^[\p{L}\p{N}.-]+$/u;

	if (splited.length < 2 || splited[1].length === 0) return false;

	if (
		!(
			(
				localPartRegex.test(splited[0]) &&
				domainPartRegex.test(splited[1].replace(/ /g, "")) &&
				(splited[1] !== "example.edu.ar" || splited[1] !== "")
			)
		)
	) {
		alert(
			"Ingresa un mail válido!\nLos caracteres permitidos son: letras, números y los siguientes caracteres especiales: _, ~, !, $"
		);
		return false;
	}

	return true;
};

const isMailAvailable = async (email) => {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/users/check-email/${email}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json", // El body se enviará en formato JSON
				},
			}
		);

		const data = await response.json();
		return data.isTaken;
	} catch (error) {
		console.error("Error en la solicitud:", error);
		return true;
	}
};

export { isEmail, isMailAvailable };