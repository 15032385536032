import React from "react";

export default function UserCard({ name, university, image, onMessageClick }) {
  return (
    <div className="flex items-center bg-dark-grey rounded-lg shadow-md p-6 w-80 h-auto">
      {/* Left Column: Image */}
      <div className="flex-shrink-0 mr-6">
        <img
          src={image}
          alt={`${name}`}
          className="w-24 h-24 rounded-full object-cover"
        />
      </div>

      {/* Right Column: Name, University, and Message Button */}
      <div className="flex flex-col justify-between flex-grow text-white">
        <div className="flex flex-col">
          <h3 className="text-xl font-semibold break-words">{name}</h3>
          <p className="text-sm break-words">{university}</p>
        </div>

        {/* Message Button */}
        <div className="mt-2">
          <button
            className="flex items-center justify-center bg-pearl text-gray-purple rounded-lg px-4 py-2 transition-all duration-300 
                            hover:bg-gray-purple hover:text-pearl"
            onClick={onMessageClick}
          >
            <span className="material-symbols-outlined text-[20px] mr-2">chat</span>
            <span>Chat</span>
          </button>
        </div>
      </div>
    </div>
  );
}
