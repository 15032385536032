import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const SentMessage = ({ message, username, time, isRead }) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '10px',
    }}>
      <div style={{
        backgroundColor: '#1890ff',
        color: 'white',
        padding: '10px 15px',
        borderRadius: '18px',
        maxWidth: '70%',
        wordWrap: 'break-word',
        position: 'relative',
      }}>
        <div style={{
          position: 'absolute',
          top: '5px',
          right: '10px',
        }}>
          <Text strong style={{ color: 'rgba(255, 255, 255, 0.9)', fontSize: '12px' }}>
            {username}
          </Text>
        </div>
        <div style={{ marginTop: '15px' }}>
          <Text style={{ color: 'white' }}>
            {message}
          </Text>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '5px',
        }}>
          <Text style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.7)' }}>
            {time}
          </Text>
          {isRead && (
            <Text style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.7)', marginLeft: '5px' }}>
              Leido
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default SentMessage;

