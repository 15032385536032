import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../supabaseClient";
import "./User.css"; // Archivo CSS para estilizar la página
import NavBar from "../../NavBar";
import { useLanguage } from "../../../utils/languageContext"; // Importa el contexto

const User = () => {
	const [userId, setUserId] = useState(null);
	const [profilePicture, setProfilePicture] = useState(null);
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [currentPassword, setCurrentPassword] = useState(""); // Nueva contraseña actual
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	//const [universityEmail, setUniversityEmail] = useState("");
	const [editingName, setEditingName] = useState(false);
	const [editingPassword, setEditingPassword] = useState(false);
	const [addingMail, setAddingMail] = useState(false);
	const [newMail, setNewMail] = useState("");
	const [availableDomains, setAvailableDomains] = useState([]); // Dominios disponibles
	const [filteredDomains, setFilteredDomains] = useState([]); // Lista filtrada por el input
	const [deletingMail, setDeletingMail] = useState(false);

	const navigate = useNavigate();

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(";").shift();
		return null;
	};

	const handleClickOutside = useCallback(
		(event) => {
			// Chequea si se hizo clic fuera de cualquier sección activa
			const sections = [
				{
					state: editingName,
					setState: setEditingName,
					className: ".info-section",
				},
				{
					state: editingPassword,
					setState: setEditingPassword,
					className: ".input-with-icon",
				},
				{
					state: addingMail,
					setState: setAddingMail,
					className: ".add-mail-section",
				},
				{
					state: deletingMail,
					setState: setDeletingMail,
					className: ".delete-mail-section",
				},
			];

			sections.forEach(({ state, setState, className }) => {
				const element = document.querySelector(className);
				if (state && element && !element.contains(event.target)) {
					if (
						state === editingPassword &&
						(password || confirmPassword || currentPassword)
					) {
						// No cerrar si hay datos en los inputs de contraseña
						return;
					}
					setState(false);
				}
			});
		},
		[
			editingName,
			editingPassword,
			addingMail,
			password,
			confirmPassword,
			currentPassword,
			deletingMail,
		]
	);

	// useEffect para manejar clicks fuera de cualquier sección editable
	useEffect(() => {
		const anySectionActive =
			editingName || editingPassword || addingMail || deletingMail;
		if (anySectionActive) {
			document.addEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [
		editingName,
		editingPassword,
		addingMail,
		deletingMail,
		handleClickOutside,
	]);

	const handleMailUpload = async () => {
		if (!newMail.includes("@")) {
			alert("Por favor, ingrese un correo válido.");
			return;
		}

		const domain = "@" + newMail.split("@")[1];

		try {
			// Obtener los dominios disponibles
			const responseDomains = await fetch(
				`${process.env.REACT_APP_API_URL}/universities/domains`
			);
			let domains = await responseDomains.json();
			// Limpiar espacios en blanco de los dominios
			domains = domains.map((d) => d.trim());

			if (!domains.includes(domain)) {
				alert(
					"El dominio del correo ingresado no corresponde a ninguna universidad registrada."
				);
				return;
			}

			// Verificar si el correo ya existe
			const checkResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/users/check-email/${newMail}`
			);
			const checkResult = await checkResponse.json();

			if (checkResult.isTaken) {
				alert("El correo ya está registrado.");
				return;
			}

			const token = getCookie("token");
			if (!token) {
				console.error("Token no encontrado en las cookies.");
				navigate("/");
				return;
			}

			// Obtener university_id asociado al dominio
			const responseUniversity = await fetch(
				`${process.env.REACT_APP_API_URL}/universities/my_university`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ token: getCookie("token") }),
				}
			);

			const universityId = await responseUniversity.json();

			// Insertar en la tabla mail
			const mailData = {
				Mail: newMail,
				university_id: universityId,
				user_id: userId,
				is_primary: false,
				is_verified: false,
			};

			const responseInsert = await fetch(
				`${process.env.REACT_APP_API_URL}/universities/mail`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(mailData),
				}
			);

			//const insertResult = await responseInsert.json();

			if (!responseInsert.ok) {
				throw new Error("Error al agregar el correo.");
			}

			alert(
				"Correo agregado exitosamente. Por favor revise su mail para terminar de verificarla!"
			);
			setAddingMail(false);
			setNewMail("");
		} catch (error) {
			console.error("Error al agregar el correo:", error);
			alert("Hubo un error al intentar agregar el correo.");
		}
	};

	const handleDeleteMail = async () => {
		if (!newMail.includes("@")) {
			alert("Por favor, ingrese un correo válido para eliminar.");
			return;
		}

		console.log("Newmail = ", newMail);
		console.log("userId = ", userId);

		try {
			const { data, error } = await supabase
				.from("mail")
				.delete()
				.match({ Mail: newMail, user_id: userId })
				.select("*");

			if (error) {
				console.error("Error al eliminar el correo:", error);
				alert("Hubo un error al intentar eliminar el correo.");
				return;
			}

			if (!data || data.length === 0) {
				alert(
					"El correo ingresado no está registrado o no pertenece al usuario actual."
				);
				return;
			}

			alert("Correo eliminado exitosamente.");
			setDeletingMail(false);
			setNewMail("");
		} catch (error) {
			console.error("Error al eliminar el correo:", error);
			alert("Hubo un error inesperado al intentar eliminar el correo.");
		}
	};

	const fetchDomains = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/universities/domains`
			);
			const domains = await response.json();
			setAvailableDomains(domains.map((d) => d.trim())); // Limpieza de dominios
		} catch (error) {
			console.error("Error al obtener dominios:", error);
		}
	};

	useEffect(() => {
		fetchDomains(); // Carga de dominios al montar el componente
	}, []);

	const handleDomainFilter = (e) => {
		//const value = e.target.value.split("@")[0]; // Texto antes del dominio
		setNewMail(e.target.value);
		if (e.target.value.includes("@")) {
			const partialDomain = e.target.value.split("@")[1];
			setFilteredDomains(
				availableDomains.filter((domain) => domain.startsWith(partialDomain))
			);
		} else {
			setFilteredDomains([]);
		}
	};

	const handleSelectDomain = (domain) => {
		const localPart = newMail.split("@")[0]; // Obtén la parte local
		setNewMail(`${localPart}${domain}`); // Completa el correo
		setFilteredDomains([]); // Oculta el desplegable
	};

	// Obtener userId al montar el componente
	useEffect(() => {
		const token = getCookie("token");
		if (!token) {
			console.error("Token no encontrado en las cookies.");
			navigate("/");
			return;
		}
		const body = { token };

		const fetchUserId = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/user/user_id`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(body),
					}
				);

				const result = await response.json();

				if (!response.ok) {
					console.error("Error validando el usuario:", result.message);
					navigate("/");
					return;
				}

				setUserId(result.userId);
			} catch (error) {
				console.error("Error en la solicitud para obtener userId:", error);
				navigate("/");
			}
		};

		fetchUserId();
	}, [navigate]);

	const fetchProfileImage = async (user_id) => {
		try {
			const { data, error } = await supabase
				.from("appuser")
				.select("profile_picture")
				.eq("user_id", user_id)
				.single();

			if (error || !data?.profile_picture) {
				console.error(
					"Error fetching profile picture ID:",
					error || "No image ID found"
				);
				setProfilePicture("");
				return;
			}

			const { data: profilePic, error: errorProfile } = await supabase
				.from("image")
				.select("base64image")
				.eq("id", data.profile_picture)
				.single();

			if (errorProfile || !profilePic?.base64image) {
				console.error(
					"Error fetching profile picture:",
					errorProfile || "No image found"
				);
				return;
			}

			setProfilePicture(profilePic.base64image);
		} catch (error) {
			console.error("Unexpected error fetching profile picture:", error);
		}
	};

	const fetchProfileName = async (user_id) => {
		try {
			const { data, error } = await supabase
				.from("appuser")
				.select("username")
				.eq("user_id", user_id)
				.single();

			if (error || !data?.username) {
				console.error(
					"Error fetching profile picture ID:",
					error || "No image ID found"
				);
				setName("");
				return;
			}

			setName(data.username);
		} catch (error) {
			console.error("Unexpected error fetching profile name:", error);
		}
	};

	// Cargar datos del perfil al obtener userId
	useEffect(() => {
		if (userId) {
			fetchProfileImage(userId);
			fetchProfileName(userId);
		}
	}, [userId]);

	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		reader.onloadend = async () => {
			const base64image = reader.result; // Base64 de la imagen

			try {
				// Obtener el ID de la imagen actual del usuario
				const { data: userData, error: userError } = await supabase
					.from("appuser")
					.select("profile_picture")
					.eq("user_id", userId)
					.single();

				if (userError || !userData?.profile_picture) {
					console.error(
						"Error obteniendo profile_picture ID:",
						userError || "No profile_picture found"
					);
					return;
				}

				const profilePictureId = userData.profile_picture;

				// Actualizar la imagen en la tabla "image"
				const { error: updateError } = await supabase
					.from("image")
					.update({ base64image })
					.eq("id", profilePictureId);

				if (updateError) {
					console.error("Error actualizando imagen de perfil:", updateError);
					return;
				}

				// Actualizar la imagen en la interfaz
				setProfilePicture(base64image);
				alert("Imagen de perfil actualizada correctamente.");
			} catch (error) {
				console.error("Error al actualizar la imagen de perfil:", error);
			}
		};

		reader.readAsDataURL(file);
	};

	const handlePasswordChange = () => {
		setEditingPassword(true);
	};

	const handleUpdateUserName = async () => {
		try {
			if (!userId) {
				console.error("No se encontró el userId para actualizar.");
				return;
			}

			// Actualizamos solo el campo 'username' en la fila correspondiente al 'userId'
			const { error } = await supabase
				.from("appuser")
				.update({ username: name }) // Actualiza el campo 'username' con el valor del estado 'name'
				.eq("user_id", userId); // Asegúrate de que el userId sea el correcto

			if (error) {
				// Verifica si el error es por una restricción UNIQUE
				if (error.code === "23505") {
					// Código estándar de error para violaciones de unicidad en PostgreSQL
					alert(
						"Error al actualizar. El username que desea ingresar ya se encuentra registrado."
					);
				} else {
					console.error("Error al actualizar el nombre de usuario:", error);
					alert("Hubo un error al actualizar el nombre de usuario.");
				}
			} else {
				alert("Nombre de usuario actualizado correctamente.");
				setEditingName(false);
			}
		} catch (err) {
			console.error("Error inesperado al guardar cambios:", err);
		}
	};

	const handleSavePassword = async () => {
		try {
			if (!currentPassword) {
				alert("Por favor, ingresa tu contraseña actual.");
				return;
			}

			if (password !== confirmPassword) {
				alert(
					"Las contraseñas no coinciden cuando intentas confirmar tu contraseña. Por favor, inténtalo de nuevo."
				);
				return;
			}

			if (!password) {
				alert("La contraseña no puede estar vacía.");
				return;
			}

			// Obtener la contraseña hasheada del usuario desde Supabase
			const { data, error: fetchError } = await supabase
				.from("appuser")
				.select("password_hash")
				.eq("user_id", userId)
				.single();

			if (fetchError || !data) {
				console.error("Error al obtener la contraseña actual:", fetchError);
				alert("Hubo un error al validar tu contraseña actual.");
				return;
			}

			const hashedOriginPassword = data.password_hash;

			// Validar la contraseña actual ingresada
			try {
				const response = fetch(
					`${process.env.REACT_APP_API_URL}/users/hash_password/${userId}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							plainPassword: currentPassword,
							hashedPassword: hashedOriginPassword,
						}),
					}
				);

				if (!response.ok) {
					throw new Error("Error al comparar contraseñas.");
				}

				const { isValid } = await response.json();

				if (!isValid) {
					alert(
						"La contraseña actual es incorrecta. Por favor, inténtalo de nuevo."
					);
					return;
				}
			} catch (e) {
				console.error("Error al comparar contraseñas:", e);
			}

			// Hashear la contraseña con bcryptjs
			//const saltRounds = 10;

			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/users/hash_password/${userId}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							password: password,
						}),
					}
				);

				if (!response.ok) {
					throw new Error("Error al hashear la contraseña.");
				}

				const hashedPassword = await response.json();

				const { error } = await supabase
					.from("appuser")
					.update({ password_hash: hashedPassword })
					.eq("user_id", userId);

				if (error) {
					console.error("Error al actualizar la contraseña:", error);
					alert("Hubo un error al actualizar tu contraseña.");
				} else {
					alert("Contraseña actualizada correctamente.");
					setPassword("");
					setConfirmPassword("");
					setCurrentPassword(""); // Limpiar el campo de contraseña actual
					setEditingPassword(false); // Salir del modo de edición
				}
			} catch (e) {
				console.error("Error al hashear la contraseña:", e);
			}
		} catch (err) {
			console.error("Error inesperado al cambiar la contraseña:", err);
			alert("Ocurrió un error inesperado. Por favor, inténtalo de nuevo.");
		}
	};

	const logOut = () => {
		document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		localStorage.clear();
		navigate("/");
	};

	const { language, translations } = useLanguage(); // Accede al contexto de idioma y traducciones

	return (
		<div>
			<NavBar></NavBar>
			<div className="user-page">
				<div className="profile-section">
					{profilePicture ? (
						<img src={profilePicture} alt="Foto de perfil" />
					) : (
						<span className="material-symbols-outlined text-grey text-[48px]">
							account_circle
						</span>
					)}
					<input
						type="file"
						accept="image/*"
						style={{ display: "none" }}
						id="fileInput"
						onChange={handleImageUpload}
					/>
					<button
						className="edit-button"
						onClick={() => document.getElementById("fileInput").click()}
					>
						{translations[language].edit_photo}
					</button>
				</div>

				<div className="info-section">
					<label>
						{translations[language].user_name}
						<div className="input-with-icon">
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								disabled={!editingName}
							/>
							<span
								className="material-symbols-outlined edit-icon"
								onClick={() => setEditingName(!editingName)}
							>
								edit
							</span>
						</div>
						{editingName && (
							<button className="confirm-button" onClick={handleUpdateUserName}>
								{translations[language].save_user_name}
							</button>
						)}
					</label>

					<label>
						{!editingPassword ? (
							<button
								className="change-password-button"
								onClick={handlePasswordChange}
							>
								{translations[language].change_password}
							</button>
						) : (
							<>
								<div className="input-with-icon">
									<input
										type={showCurrentPassword ? "text" : "password"}
										value={currentPassword}
										placeholder={translations[language].enter_actual_password}
										onChange={(e) => setCurrentPassword(e.target.value)}
									/>
									<span
										className="material-symbols-outlined eye-icon"
										onClick={() => setShowCurrentPassword(!showCurrentPassword)}
									>
										{showCurrentPassword ? "visibility_off" : "visibility"}
									</span>
								</div>

								<div className="input-with-icon">
									<input
										type={showPassword ? "text" : "password"}
										value={password}
										placeholder={translations[language].enter_new_password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<span
										className="material-symbols-outlined eye-icon"
										onClick={() => setShowPassword(!showPassword)}
									>
										{showPassword ? "visibility_off" : "visibility"}
									</span>
								</div>

								<div className="input-with-icon">
									<input
										type={showConfirmPassword ? "text" : "password"}
										value={confirmPassword}
										placeholder={translations[language].confirm_new_password}
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>
									<span
										className="material-symbols-outlined eye-icon"
										onClick={() => setShowConfirmPassword(!showConfirmPassword)}
									>
										{showConfirmPassword ? "visibility_off" : "visibility"}
									</span>
								</div>
								{editingPassword && (
									<button
										className="confirm-button"
										onClick={handleSavePassword}
									>
										{translations[language].save_new_password}
									</button>
								)}
							</>
						)}
					</label>

					<label>
						<button
							className="add-university-email"
							onClick={() => setAddingMail(true)}
						>
							{translations[language].add_other_uni_email}
						</button>
					</label>

					{addingMail && (
						<div className="add-mail-section">
							<input
								type="email"
								value={newMail}
								placeholder={translations[language].other_uni_email}
								onInput={handleDomainFilter}
							/>
							{filteredDomains.length > 0 && (
								<ul className="domain-dropdown">
									{filteredDomains.map((domain, index) => (
										<li
											key={index}
											onClick={() => handleSelectDomain(domain)}
											className="domain-item"
										>
											{domain}
										</li>
									))}
								</ul>
							)}
							<button className="confirm-button" onClick={handleMailUpload}>
								{translations[language].confirm}
							</button>
						</div>
					)}

					<label>
						<button
							className="add-university-email"
							onClick={() => setDeletingMail(true)}
						>
							{translations[language].delete_other_uni_email}
						</button>
					</label>

					{deletingMail && (
						<div className="delete-mail-section">
							<input
								type="email"
								value={newMail}
								placeholder={translations[language].to_delete_email}
								onInput={handleDomainFilter}
							/>
							{filteredDomains.length > 0 && (
								<ul className="domain-dropdown">
									{filteredDomains.map((domain, index) => (
										<li
											key={index}
											onClick={() => handleSelectDomain(domain)}
											className="domain-item"
										>
											{domain}
										</li>
									))}
								</ul>
							)}

							<button className="confirm-button" onClick={handleDeleteMail}>
								{translations[language].delete}
							</button>
						</div>
					)}
				</div>

				<button className="logout-button" onClick={logOut}>
					{translations[language].close_session}
				</button>
			</div>
		</div>
	);
};

export default User;
