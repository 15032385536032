import React, { useState, useEffect, useCallback } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import "./LodgmentDetailCarousel.css";

const LodgmentDetailCarousel = ({ images }) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const nextSlide = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
	}, [images.length]);

	const previousSlide = useCallback(() => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length
		);
	}, [images.length]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "ArrowLeft") {
				previousSlide();
			} else if (event.key === "ArrowRight") {
				nextSlide();
			}
		};

		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [nextSlide, previousSlide]);

	return (
		<div className="carousel">
			<div
				className="carousel-slide-container"
				style={{ transform: `translateX(-${currentIndex * 100}%)` }}
			>
				{images.map((image, index) => (
					<img
						src={image}
						key={index}
						className="carousel-slide"
						alt={`Slide ${index + 1}`}
					/>
				))}
			</div>
			{images.length > 1 && (
				<>
					<button className="carousel-arrow carousel-arrow-left" onClick={previousSlide}>
						<IoIosArrowBack />
					</button>
					<button className="carousel-arrow carousel-arrow-right" onClick={nextSlide}>
						<IoIosArrowForward />
					</button>

					<div className="carousel-indicators">
						{images.map((_, index) => (
							<button
								key={index}
								onClick={() => setCurrentIndex(index)}
								className={
									currentIndex === index
										? "carousel-indicator"
										: "carousel-indicator carousel-indicator-inactive"
								}
								aria-label={`Go to slide ${index + 1}`}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default LodgmentDetailCarousel;
