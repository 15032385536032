import React, { useState } from "react";

import "./UserProfile.css";
import fetchUserInfo from "../../behaviour/fetches/fetchUserInfo";
import { CloseOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const contactUser = (userId) => {
	return;
};

function UserProfile({ setProfileOpen, userId }) {
	const [userInfo, setUserInfo] = useState(null);

	useState(() => {
		const fetchInfo = async (userId) => {
			await fetchUserInfo(userId).then((info) => setUserInfo(info));
		};
		fetchInfo(userId);
	}, []);

	return (
		<div className="user-profile-canvas">
			<div className="user-profile-box">
				{userInfo === null ? (
					<Spin size="large" />
				) : (
					<div className="user-profile-info">
						<div id="left">
							<img src={userInfo[0].profile_picture} alt="User profile" />
						</div>
						<div id="right">
							<div id="top">
								<div onClick={() => setProfileOpen(false)} id="close-button">
									<CloseOutlined />
								</div>
								<h1 style={{ fontSize: "30px" }}>{userInfo[0].username}</h1>
								<h3>{userInfo[0].university_name}</h3>
							</div>
							<div id="bottom">
								<button
									className="bg-grey hover:bg-green-700 text-white py-2 px-4 rounded border "
									id="user-chat"
									onClick={() => {
										contactUser(userId);
                                        setProfileOpen(false);
									}}
								>
									Contactar
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default UserProfile;
