import React from "react";
import "./UsernamePrompt.css";
import { useLanguage } from "../../../../utils/languageContext";

const UsernamePrompt = ({ onUsernameChange }) => {
  const { language, translations } = useLanguage();

  return (
    <div className="user-input">
      <input
        className="font-playWrite"
        id="user"
        placeholder={translations[language].username}
        onChange={(e) => onUsernameChange(e)}
      />
    </div>
  );
};

export default UsernamePrompt;
