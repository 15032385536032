import { useRef, useState } from "react";
import ImagesUpload from "../imagesUpload/ImagesUpload";

import { message } from "antd";
import { supabase } from "../../../supabaseClient";
import { getCookie } from "../../behaviour/getCookies";
import AddressAutocompleteMixed from "../../geo/Geoconvertermixed";
import QuillPrompt from "../quill/QuillPrompt";
import "./LodgmentAdderCanvas.css";
import { useLanguage } from "../../../utils/languageContext";
import { translations } from "../../../utils/translations";

const charCounter = (inputField, pId) => {
	const maxLength = inputField.getAttribute("maxlength");
	const currentLength = inputField.value.length;
	const remChars = document.getElementById(pId);

	if (currentLength === 0) {
		remChars.innerHTML = "";
	} else {
		remChars.innerHTML = `${maxLength - currentLength} characters left`;
	}
};

const checkBody = async (body) => {
	if (body.token === null) {
		return "You must be logged in to post a lodge";
	}

	if (body.title.length < 4) {
		return "Title must be at least 4 characters long";
	}

	if (body.chosenLocation === null) {
		return "You must choose a location";
	}

	if (body.briefDescription.length < 20) {
		return "Description must be at least 20 characters long";
	}

	const totalChars = body.content.ops.reduce((acc, op) => {
		if (typeof op.insert === "string") {
			const visibleChars = op.insert.replace(/[\s\n\t]/g, "");
			return acc + visibleChars.length;
		}
		return acc;
	}, 0);

	if (totalChars < 125) {
		return "Description must be at least 125 characters long";
	}

	if (body.content.ops.length === 1 && body.content.ops[0].insert === "\n") {
		return "You must write a description";
	}

	if (body.fileList.length === 0) {
		return "You must upload at least one image";
	}

	return "";
};

const uploadFile = async (file) => {
	try {
		const fileName = `${Date.now()}-${file.name}`;
		/* eslint-disable no-unused-vars */
		const { data, error } = await supabase.storage
			.from("lodgment_images")
			.upload(fileName, file);
		/* eslint-enable no-unused-vars */

		if (error) {
			throw error;
		}

		const { data: publicData } = supabase.storage
			.from("lodgment_images")
			.getPublicUrl(fileName);

		return publicData.publicUrl;
	} catch (error) {
		message.error("Failed to upload image: " + error.message);
		return null;
	}
};

const uploadImage = async (base64String) => {
	try {
		const fileType = base64String.match(/^data:image\/(.*?);base64/)[1];
		const fileName = `${Date.now()}.${fileType}`;
		const base64Data = base64String.split(",")[1];

		const blob = new Blob(
			[Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
			{
				type: `image/${fileType}`,
			}
		);

		/* eslint-disable no-unused-vars */
		const { data, error } = await supabase.storage
			.from("lodgment_detail_images")
			.upload(fileName, blob);
		/* eslint-enable no-unused-vars */
			

		if (error) {
			throw new Error("Failed to upload image: " + error.message);
		}

		const { data: publicData } = supabase.storage
			.from("lodgment_detail_images")
			.getPublicUrl(fileName);

		return publicData.publicUrl;
	} catch (error) {
		console.error(error);
		return null;
	}
};

const LodgmentAdderCanvas = ({ setAdderOpen }) => {
	const [title, setTitle] = useState("");
	const [briefDescription, setBriefDescription] = useState("");
	const [fileList, setFileList] = useState([]);
	const [chosenLocation, setChosenLocation] = useState(null);
	const quillRef = useRef(null);
	const [isPublishing, setIsPublishing] = useState(false);
	//const { language, toggleLanguage } = useLanguage();
	const { language } = useLanguage();

	const storeLodge = async (content) => {
		const token = getCookie("token");

		const errorMessage = await checkBody({
			title,
			briefDescription,
			content,
			chosenLocation,
			fileList,
			token,
		});

		if (errorMessage) {
			return { success: false, message: errorMessage };
		}

		const base64Images = content.ops.filter(
			(op) =>
				op.insert && op.insert.image && op.insert.image.startsWith("data:image")
		);

		const uploadPromises = base64Images.map(async (imageOp) => {
			const publicUrl = await uploadImage(imageOp.insert.image);
			imageOp.insert.image = publicUrl || imageOp.insert.image;
		});

		await Promise.all(uploadPromises);

		const latitude = chosenLocation.lat;
		const longitude = chosenLocation.lon;

		for (const file of fileList) {
			if (!file.url && file.originFileObj) {
				const url = await uploadFile(file.originFileObj);
				if (url) {
					file.url = url;
				}
			}
		}

		const body = {
			title,
			briefDescription,
			value: content,
			latitude,
			longitude,
			fileList,
			token,
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/lodging/store_lodge`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body),
				}
			);

			const data = await response.json();
			if (response.ok) {
				return { success: true, message: "Lodge registered successfully" };
			} else {
				console.error("Error al registrar el hospedaje:", data.message);
				return {
					success: false,
					message: "Error al registrar usuario: " + data.message,
				};
			}
		} catch (e) {
			console.error("Error en la solicitud:", e);
			return { success: false, message: "Error en la solicitud" };
		}
	};

	return (
		<div className="lodgment-adder-canvas">
			<div className="lodgment-adder-box">
				<h3 className="h3">{translations[language].add_lodgment}</h3>
				<div className="lab-title-box">
					<input
						className="lab-title"
						type="text"
						placeholder={translations[language].lodgment_title}
						maxLength="100"
						value={title}
						onInput={(e) => {
							charCounter(e.target, "lab-title-remaining-chars");
							setTitle(e.target.value);
						}}
					/>
					<p id="lab-title-remaining-chars" />
				</div>
				<AddressAutocompleteMixed setChosenLocation={setChosenLocation} />
				<div className="lab-description-box">
					<div className="lab-brief-description">
						<textarea
							className="lab-description"
							maxLength="300"
							placeholder={translations[language].lodgment_description}
							value={briefDescription}
							onInput={(e) => {
								charCounter(e.target, "lab-description-remaining-chars");
								setBriefDescription(e.target.value);
							}}
						/>
						<p id="lab-description-remaining-chars" />
					</div>
					<QuillPrompt quillRef={quillRef} />
				</div>
				<div className="lab-upload">
					<p>{translations[language].lodgment_photos}: {fileList.length}/8</p>
					<ImagesUpload
						id="upload-component"
						fileList={fileList}
						setFileList={setFileList}
					/>
				</div>

				<div className="flex justify-end space-x-2 mt-4">
					<button
						onClick={() => setAdderOpen((prev) => !prev)}
						className="bg-grey hover:bg-gray-700 text-white py-2 px-4 rounded border"
					>
						{translations[language].lodgment_cancel}
					</button>
					<button
						className="bg-grey hover:bg-green-700 text-white py-2 px-4 rounded border "
						onClick={async () => {
							setIsPublishing(true);
							const success = await storeLodge(quillRef.current.getContents());
							if (success.success) {
								setAdderOpen((prev) => !prev);
							} else {
								alert(success.message);
								setIsPublishing(false);
							}
						}}
						disabled={isPublishing}
					>
						{translations[language].lodgment_publish}
					</button>
				</div>
			</div>
		</div>
	);
};

export default LodgmentAdderCanvas;
