import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LodgmentDetailCarousel from "../../lodgment/carousel/LodgmentDetailCarousel";
import NavBar from "../../NavBar";

import "./LodgmentDetail.css";
import getLocationFromCoordinates from "../../behaviour/getLocationFromCoordinates";
import deletePost from "../../behaviour/deletePost";
import { DeleteOutlined } from "@ant-design/icons";

function LodgmentDetail() {
	const location = useLocation();
    const navigate = useNavigate();

	const { post } = location.state;

	const [lodge, setLodge] = useState(null);
	const [isAdmin, setIsAdmin] = useState(null);
    const [isOwner, setIsOwner] = useState(null);
	const [username, setUsername] = useState(null);
	const [deleteIsOpen, setDeleteIsOpen] = useState(false);
	const [postLocation, setPostLocation] = useState(null);

	const getLodge = async (postId) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/lodging/get_lodge/${postId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (!response.ok) {
				throw new Error("Error fetching post");
			}

			const fetchedPost = await response.json();
			setLodge(fetchedPost.data[0]);
		} catch (e) {
			console.error("Error fetching post:", e);
		}
	};

	const getUser = async (userId) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/user/get_user_info/${userId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (!response.ok) {
				throw new Error("Error fetching user");
			}

			const fetchedUser = await response.json();
			setUsername(fetchedUser.data[0].username);
			setIsAdmin(fetchedUser.data[0].is_admin);
            setIsOwner(fetchedUser.data[0].user_id === post.user_id);
		} catch (e) {
			console.error("Error fetching user:", e);
		}
	};

	useEffect(() => {
		const fetchLocation = async () => {
			const pLocation = await getLocationFromCoordinates(
				post.latitud,
				post.longitud
			);
			setPostLocation(pLocation);
		};
		fetchLocation();
	}, [post]);

	useEffect(() => {
		getLodge(post.id);
		getUser(post.user_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [post]);

	const convertDeltaToHtml = (delta) => {
		if (!delta || !delta.ops) {
			return "";
		}
		const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
		return converter.convert();
	};

	return (
		<div className="lodgment-detail">
			<div>
				<NavBar
					sections={[
						"Beneficios",
						"Actividades",
						"Hospedajes",
						"Conectarse",
						"Chats",
					]}
				/>
			</div>
			<div className="lodgment-detail-page">
				<div id="carousel-container">
					<LodgmentDetailCarousel images={post.images} />
				</div>
				<div id="text-container">
					<div id="author-info">
						<img id="author-avatar" src={post.profile_picture} alt="Avatar" />
						<div id="author-username">
							<h1 style={{ fontWeight: "bolder" }}>Por: {username}</h1>
						</div>
						{(isAdmin || isOwner)&& (
							<div className="lodgment-admin-delete">
								<div
									onClick={() => setDeleteIsOpen(!deleteIsOpen)}
									className="delete-lodgment-button"
								>
									<DeleteOutlined style={{ fontSize: "30px" }} />
								</div>
								{deleteIsOpen && (
									<div className="delete-lodgment-modal">
										<h1>¿Estás seguro que deseas eliminar este hospedaje?</h1>
										<button
											onClick={() => {
												deletePost(post.id);
												setDeleteIsOpen(false);
                                                navigate("/Hospedajes");
											}}
										>
											Eliminar
										</button>
										<button
											onClick={() => {
												setDeleteIsOpen(false);
											}}
										>
											Cancelar
										</button>
									</div>
								)}
							</div>
						)}
					</div>
					<div id="text">
						<h1 id="title" style={{ fontSize: "25px", fontWeight: "bolder" }}>
							{post.name}
						</h1>
						<div id="location">
							<h3 style={{ fontWeight: "lighter" }}>{postLocation}</h3>
						</div>
						<hr className="hr" />
						<div
							id="description"
							dangerouslySetInnerHTML={{
								__html: lodge ? convertDeltaToHtml(lodge.long_description) : "",
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LodgmentDetail;
