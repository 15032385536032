// FloatingButton.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'

function FloatingButton({
    showForm,
    handleButtonClick,
    handleFormClose,
    handleSubmit,
    handleImageUpload,
    setName,
    setDescription, 
    setSelectedCategory,
    selectedCategory,
}) {

    const [categories, setCategories] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const { data, error } = await supabase
                    .from('category')
                    .select('name'); // Obtén solo el nombre de las categorías

                if (error) {
                    console.error('Error al obtener categorías:', error.message);
                } else {
                    setCategories(data.map((category) => category.name)); // Solo los nombres
                }
            } catch (err) {
                console.error('Error inesperado:', err.message);
            }
        };

        fetchCategories();
    }, []);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setShowDropdown(false);
    };


    return (
        <div className="relative">
            <div
                id="popup-tooltip"
                className="absolute bottom-0 right-5 bg-black text-white text-sm px-2 py-1 rounded opacity-90 z-20"
                style={{ display: 'none' }}
            >
                Agregar beneficio
            </div>
            <button
                className="fixed bottom-4 right-5 bg-grey text-white font-bold rounded-2xl w-[60px] h-[60px] flex items-center justify-center text-4xl hover:bg-grey-20 z-10"
                onClick={handleButtonClick}
                style={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.50)' }}  // Sombra personalizada
                onMouseEnter={() => document.getElementById('popup-tooltip').style.display = 'block'}
                onMouseLeave={() => document.getElementById('popup-tooltip').style.display = 'none'}
            >
                +
            </button>


            {showForm && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
                        <h2 className="text-2xl mb-4">Ingresar Beneficio</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-lg font-medium mb-1">Imagen</label>
                                <input type="file" accept="image/*" required onChange={handleImageUpload} className="border rounded px-3 py-2 w-full" />
                            </div>
                            <div>
                                <label className="block text-lg font-medium mb-1">Nombre</label>
                                <input type="text" required onChange={e => setName(e.target.value)} className="border rounded px-3 py-2 w-full" />
                            </div>
                            <div>
                                <label className="block text-lg font-medium mb-1">Descripción</label>
                                <textarea required onChange={e => setDescription(e.target.value)} className="border rounded px-3 py-2 w-full"></textarea>
                            </div>

                            <div>
                                <label className="block text-lg font-medium mb-1">Categoría</label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        value={selectedCategory}
                                        onClick={() => setShowDropdown(!showDropdown)}
                                        readOnly
                                        placeholder="Seleccionar categoría"
                                        className="border rounded px-3 py-2 w-full cursor-pointer"
                                    />
                                    {showDropdown && (
                                        <ul className="absolute z-30 bg-white border rounded w-full mt-1 max-h-40 overflow-auto">
                                            {categories.map((category, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleCategorySelect(category)}
                                                    className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                                                >
                                                    {category}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <div className="flex justify-end space-x-2 mt-4">
                                <button
                                    type="button"
                                    onClick={handleFormClose}
                                    className="bg-grey hover:bg-gray-700 text-white py-2 px-4 rounded border"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-grey hover:bg-green-700 text-white py-2 px-4 rounded border "
                                >
                                    Agregar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FloatingButton;
