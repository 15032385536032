async function getLocationFromCoordinates(latitude, longitude) {
	const apiKey = "AIzaSyANNyFLaW2LSvU9iJk9eqjFIDeTTOCtyYA";
	const response = await fetch(
		`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
	);
	const data = await response.json();
	if (data.status === "OK" && data.results.length > 0) {
		return data.results[0].formatted_address;
	} else {
		return "Unknown location";
	}
};

export default getLocationFromCoordinates;
