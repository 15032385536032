async function deletePost (postId) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/lodging/delete_lodge/${postId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );

        if (!response.ok) {
            throw new Error("Error deleting post");
        }

        const result = await response.json();
        console.log(result.data);
        return result.data;
    } catch (e) {
        console.error(e);
    }
};

export default deletePost;