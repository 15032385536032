import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination, Spin } from "antd";

import LodgmentAdderButton from "../../lodgment/lodgmentAdderButton/LodgmentAdderButton";
import LodgmentAdderCanvas from "../../lodgment/lodgmentAdderCanvas/LodgmentAdderCanvas";
import MyLodgmentsEditor from "../../lodgment/myLodgmentsEditor/MyLodgmentsEditor";
import AddressAutocompleteMixed from "../../geo/Geoconvertermixed";
import CardCarousel from "../../carrouselCard";
import NavBar from "../../NavBar";
import UserProfile from "../../lodgment/userProfile/UserProfile";
import fetchUserInfo from "../../behaviour/fetches/fetchUserInfo";
import { getCookie } from "../../behaviour/getCookies";

const fetchMyPosts = async () => {
	try {

		const token = getCookie("token");
      	if (token === null) {
          throw new Error("Error buscando token");
      	}
      	const body = { token };
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/user/get_user_lodgments`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			
			}
		);

		if (!response.ok) {
			throw new Error("Error fetching posts");
		}

		const posts = await response.json();
		return posts.data;
	} catch (error) {
		console.error("Error fetching posts:", error);
	}
};

const CardPage = () => {
	const rareUniversityId = localStorage.getItem("selectedUniversityId");
	const universityId = JSON.parse(rareUniversityId);
	// eslint-disable-next-line
	const [defaultLocation, setDefaultLocation] = useState(null);
	const [chosenLocation, setChosenLocation] = useState([]);
	const [posts, setPosts] = useState([]);
	const [myPosts, setMyPosts] = useState([]);

	const [adderOpen, setAdderOpen] = useState(false);
	const [editorOpen, setEditorOpen] = useState(false);
	const [profileOpen, setProfileOpen] = useState(false);
	const [userPostId, setUserPostId] = useState({});

	const navigate = useNavigate();

	useEffect(() => {
		const fetchCoordinates = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/universities/coordinates/${universityId}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				if (!response.ok) {
					throw new Error("Error fetching coordinates");
				}

				const coordinates = await response.json();
				setDefaultLocation(coordinates);
				const location = {
					lat: coordinates.latitud,
					lon: coordinates.longitud,
				};
				setChosenLocation(location);
			} catch (error) {
				console.error("Error fetching coordinates:", error);
			}
		};
		fetchCoordinates();
	}, [universityId]);

	useEffect(() => {
		const fetchPosts = async () => {
			if (chosenLocation.lat != null && chosenLocation.lon != null) {
				try {
					const response = await fetch(
						`${process.env.REACT_APP_API_URL}/lodging/lodging_post/${chosenLocation.lat}/${chosenLocation.lon}/10`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						}
					);

					if (!response.ok) {
						if (response.status === 404) {
							setPosts([]);
						}
						throw new Error("Error fetching posts");
					}

					const posts = await response.json();

					const cardsArray = posts.map((post, index) => (
						<CardCarousel
							key={index}
							images={post.images}
							title={post.name}
							userId={post.user_id}
							description={post.brief_description}
							avatarUrl={post.profile_picture}
							onClick={() =>
								navigate(`/Hospedajes/${post.id}`, {
									state: {
										post: post,
									},
								})
							}
							setProfileOpen={setProfileOpen}
							setUserPostId={setUserPostId}
						/>
					));

					setPosts(cardsArray);
				} catch (error) {
					console.error("Error fetching posts:", error);
				}
			}
		};
		fetchPosts();
	}, [chosenLocation, navigate]);

	useEffect(() => {
		async function fetchData() {
			const myPosts = await fetchMyPosts();
			const groupedPosts = myPosts.reduce((acc, post) => {
				if (!acc[post.id]) {
					acc[post.id] = { ...post, images: [] };
				}
				acc[post.id].images.push(post.base64image);
				return acc;
			}, {});

			for (const postId in groupedPosts) {
				const post = groupedPosts[postId];
				const userInfo = await fetchUserInfo(post.user_id);
				post.profile_picture = userInfo[0].profile_picture;
			}

			setMyPosts(Object.values(groupedPosts));
		}
		fetchData();
	}, []);

	// Estado para manejar la paginación
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 8;

	// Obtener las tarjetas correspondientes a la página actual
	const paginatedCards = posts.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	// Manejar el cambio de página
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="relative">
			{/* NavBar */}
			<div>
				<NavBar/>
			</div>

			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "row",
					gap: "20px",
					marginBottom: "40px",
					marginTop: "40px",
				}}
			>
				<AddressAutocompleteMixed setChosenLocation={setChosenLocation} />
			</div>

			{/* Galería de tarjetas paginadas */}
			{paginatedCards.length === 0 ? (
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						gap: "20px",
					}}
				>
					<Spin size="large" />
				</div>
			) : (
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						gap: "20px",
					}}
				>
					{paginatedCards}
				</div>
			)}

			{/* Paginación con botones comprimidos */}
			<div
				style={{ display: "flex", justifyContent: "center", padding: "40px" }}
			>
				<Pagination
					current={currentPage}
					pageSize={pageSize}
					total={posts.length}
					onChange={handlePageChange}
					showSizeChanger={false} // Ocultar el selector de tamaño de página
					showLessItems // Mostrar la versión comprimida de la paginación
				/>
			</div>

			<LodgmentAdderButton
				setAdderOpen={setAdderOpen}
				setEditorOpen={setEditorOpen}
			/>
			{adderOpen && <LodgmentAdderCanvas setAdderOpen={setAdderOpen} />}
			{editorOpen && (
				<MyLodgmentsEditor setEditorOpen={setEditorOpen} myPosts={myPosts} />
			)}
			{profileOpen && (
				<UserProfile setProfileOpen={setProfileOpen} userId={userPostId} />
			)}
		</div>
	);
};

export default CardPage;
