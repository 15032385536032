import {
	CNavbar,
	CContainer,
	CNavbarBrand,
	CNavbarToggler,
	CCollapse,
	CNavbarNav,
	CButton,
} from "@coreui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLanguage } from "../utils/languageContext";
import { translations } from "../utils/translations";
import { useMemo } from "react";
//import { supabase } from "../supabaseClient";

export default function NavBar() {
	const navigate = useNavigate(); // Para la navegación
	const location = useLocation(); // Para obtener la URL actual
	const [visible, setVisible] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0); // "Beneficios" será el valor inicial (índice 0)
	//const [open, setOpen] = useState("");
	const [user_id, setUserId] = useState(null);
	const [profilePicture, setProfilePicture] = useState(null);
	const [userUniversity, setUserUniversity] = useState(null);

	const { language, toggleLanguage } = useLanguage();

	// Definir el vector de secciones con rutas reales y nombres traducidos
	const sections = useMemo(
		() => [
			{ route: "Beneficios", label: translations[language].section_benefits },
			{
				route: "Actividades",
				label: translations[language].section_activities,
			},
			{
				route: "Hospedajes",
				label: translations[language].section_accommodations,
			},
			{
				route: "Contactarse",
				label: translations[language].section_get_in_touch,
			},
			{ route: "Chats", label: translations[language].section_chats },
		],
		[language]
	);

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(";").shift();
		return null; // Return null if the cookie is not found
	};

	// Obtener userId al montar el componente
	useEffect(() => {
		const token = getCookie("token");
		if (!token) {
			console.error("Token no encontrado en las cookies.");
			navigate("/");
			return;
		}
		const body = { token };

		const fetchUserId = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/user/user_id`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(body),
					}
				);

				const result = await response.json();

				if (!response.ok) {
					console.error("Error validando el usuario:", result.message);
					navigate("/");
					return;
				}

				setUserId(result.userId);
			} catch (error) {
				console.error("Error en la solicitud para obtener userId:", error);
				navigate("/");
			}
		};

		fetchUserId();
	}, [navigate]);

	const fetchUserInfo = async (user_id) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/user/get_user_info/${user_id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (!response.ok) {
				throw new Error("Error fetching user");
			}

			const fetchedUser = await response.json();
			const minorWords = new Set([
				"de",
				"y",
				"el",
				"la",
				"los",
				"las",
				"del",
				"al",
				"un",
				"una",
				"unos",
				"unas",
				"con",
				"sin",
				"en",
				"por",
				"para",
				"a",
				"que",
				"se",
				"su",
				"o",
				"u",
				"e",
			]);
			const words =
				fetchedUser.data[0].university_name.match(/[\p{L}@#$%&]+/gu) || [];
			const spelling = words
				.filter((word) => !minorWords.has(word.toLowerCase()))
				.map((word) => word[0])
				.join("")
				.toUpperCase();
			setUserUniversity(spelling);
			setProfilePicture(fetchedUser.data[0].profile_picture);
		} catch (e) {
			console.error("Error fetching user:", e);
		}
	};

	// Cargar la imagen de perfil al obtener userId
	useEffect(() => {
		if (user_id) {
			fetchUserInfo(user_id);
		}
	}, [user_id]);

	useEffect(() => {
		// Verificar si estamos en la ruta '/User' y no hacer nada
		if (location.pathname === '/User') {
		  setActiveIndex(-1); // -1 representa que no hay sección activa
		} else {
		  const currentIndex = sections.findIndex((section) =>
			location.pathname.includes(section.route)
		  );
		  setActiveIndex(currentIndex === -1 ? 0 : currentIndex); // Si no se encuentra una ruta, marcar como 0 por defecto
		}
	  }, [location.pathname, sections]);
	  

	const handleSectionClick = (index, route) => {
		setActiveIndex(index); // Actualiza el índice de la sección activa
		navigate(`/${route}`); // Redirecciona a la ruta correspondiente
	};

	const handleHome = () => {
		navigate(`/Beneficios`);
	};

	/*const logOut = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    navigate("/");
  };*/

	const handleProfileClick = () => {
		navigate(`/User`); //ACA TENGO QUE PONER USER. Hago que salga solo para probar.
	};

	return (
		<div className="bg-grey">
			<CNavbar expand="lg">
				<CContainer fluid className="position-relative">
					{/* Logo StudentHub */}
					<CNavbarBrand
						className="text-white text-3xl"
						onClick={handleHome}
						style={{ cursor: "pointer" }}
					>
						StudentHub | {userUniversity}
					</CNavbarBrand>

					<CNavbarToggler
						aria-label="Toggle navigation"
						aria-expanded={visible}
						onClick={() => setVisible(!visible)}
					/>

					{/* Secciones centradas */}
					<CCollapse className="navbar-collapse" visible={visible}>
						<CNavbarNav
							as="nav"
							className="position-absolute start-50 translate-middle-x d-flex"
						>
							{sections.map((section, index) => (
								<button
									key={index}
									className={`text-white mx-3 text-xl ${
										activeIndex === index ? "underline underline-offset-4" : ""
									}`}
									onClick={() => handleSectionClick(index, section.route)}
								>
									{section.label}
								</button>
							))}
						</CNavbarNav>
					</CCollapse>

					{/* Botón de acceso a perfil */}
					<CButton
						className="me-md-2 text-white d-flex justify-content-center align-items-center bg-white profile-button"
						type="button"
						shape="rounded-pill"
						style={{
							width: "50px",
							height: "50px",
							background: "none",
							padding: 0,
						}}
						onClick={handleProfileClick}
					>
						{profilePicture ? (
							<img
								src={profilePicture}
								alt="Profile"
								style={{ width: "100%", height: "100%", borderRadius: "50%" }}
							/>
						) : (
							<span
								style={{ position: "relative", left: "0.5px", top: "0.5px" }}
								className="material-symbols-outlined text-grey text-[48px]"
							>
								account_circle
							</span>
						)}
					</CButton>
					{/* Botón EN/ES */}
					<div className="d-flex align-items-center">
						<div
							onClick={toggleLanguage}
							style={{
								cursor: "pointer",
								fontWeight: "bold",
								color: "white",
								textDecoration: language === "es" ? "underline" : "none",
								marginRight: "5px",
							}}
						>
							ES
						</div>
						<div
							style={{
								fontWeight: "bold",
								color: "white",
							}}
						>
							/
						</div>
						<div
							onClick={toggleLanguage}
							style={{
								cursor: "pointer",
								fontWeight: "bold",
								color: "white",
								textDecoration: language === "en" ? "underline" : "none",
								marginLeft: "5px",
							}}
						>
							EN
						</div>
					</div>
				</CContainer>
			</CNavbar>
		</div>
	);
}
