async function fetchUserInfo(userId) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/get_user_info/${userId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );

        if (!response.ok) {
            throw new Error("Error fetching user info");
        }

        const userInfo = await response.json();
        return userInfo.data;
    } catch (e) {
        console.error(e);
    }
};

export default fetchUserInfo;