import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../../../supabaseClient';
import NavBar from '../../NavBar';
import DetailCard from '../../DetailCard';
import DeleteFloatingButton from '../../DeleteFloatingButton';

const BenefitDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const benefitId = queryParams.get('benefitId');

  const [benefitDetails, setBenefitDetails] = useState([]);
  const [benefitImages, setBenefitImages] = useState([]);

  useEffect(() => {
    const fetchBenefitDetails = async () => {
      try {
        const { data: benefitData, error: benefitError } = await supabase
          .from('benefit')
          .select('name, description')
          .eq('id', benefitId)
          .single();

        if (benefitError) throw benefitError;

        const { data: imageData, error: imageError } = await supabase
          .from('imagebenefit')
          .select('image_id')
          .eq('benefit_id', benefitId);

        if (imageError) throw imageError;

        const imageIds = imageData.map(ib => ib.image_id);

        const { data: imagesData, error: imagesError } = await supabase
          .from('image')
          .select('base64image')
          .in('id', imageIds);

        if (imagesError) throw imagesError;

        setBenefitDetails(benefitData);
        setBenefitImages(imagesData.map(img => img.base64image));
      } catch (error) {
        console.error('Error fetching benefit details or images:', error);
      }
    };

    fetchBenefitDetails();
  }, [benefitId]);


  const handleDeleteConfirm = async () => {
    try {
      const { error } = await supabase
        .from('benefit')
        .delete()
        .eq('id', benefitId);

      if (error) throw error;

      console.log('Benefit deleted successfully');
      navigate('/Beneficios'); // Redirect to the benefits list page
    } catch (error) {
      console.error('Error deleting benefit:', error);
    }
  };

  return (
    <div className="min-h-screen bg-pearl">
      {/* NavBar */}
      <NavBar/>

      {/* Detalle de beneficios */}
      <DetailCard title={benefitDetails.name} description={benefitDetails.description} image={benefitImages[0]} bgColor="bg-nardo-gray"/>

      {/* Delete Floating Button */}
      <DeleteFloatingButton handleDeleteConfirm={handleDeleteConfirm} />
    </div>
  );
};

export default BenefitDetail;

