import React from 'react';
import { Typography, Divider } from 'antd';

const { Text } = Typography;

const UnreadMessagesDivider = () => {
  return (
    <Divider plain style={{ color: '#444', fontSize: '14px' }}>
      <Text strong>Mensajes no leidos</Text>
    </Divider>
  );
};

export default UnreadMessagesDivider;

