import NavBar from "../../NavBar";
import { useNavigate} from "react-router-dom";
import ActivityTypeButton from "./ActivityTypeButton";
import turisticIcon from './images/turisticas.png';
import socialIcon from './images/sociales.png';
import academicIcon from './images/academicas.png';
import { useLanguage } from "../../../utils/languageContext";

export default function ActivityPage () {
    const navigate = useNavigate();
    const { language, translations } = useLanguage();

    const rareUniversityId = localStorage.getItem("selectedUniversityId");
    const university_id = JSON.parse(rareUniversityId);

    const handleTuristicClick = () => {
        //const university_id = "de61ec4a-dc96-46ec-a951-572332f10477";
        // Redirige a una página de actividades basada en la categoría seleccionada
        navigate(`/Actividades/${university_id}/turisticPage`);
    };

  const handleSocialClick = () => {
    //const university_id = "de61ec4a-dc96-46ec-a951-572332f10477";
    // Redirige a una página de actividades basada en la categoría seleccionada
    navigate(`/Actividades/${university_id}/socialPage`);
  };

    const handleAcademicClick = () => {
        //const university_id = "de61ec4a-dc96-46ec-a951-572332f10477";  // Aquí puedes hardcodear o calcular este valor
        navigate(`/Actividades/${university_id}/academicPage`);
    };


  return (
    <div className="flex flex-col min-h-screen">
        {/* NavBar */}
        <div>
            <NavBar/>
        </div>

        <div className="flex flex-col items-center flex-grow">
            {/* Texto descriptivo */}
            <div className="font-roboto text-center my-4 mb-2 px-10">
                <h1 className="text-4xl font-bold mt-4 text-gray-purple">
                    {translations[language].activities_title}
                </h1>
                <p className="text-2xl text-gray-purple mt-4 mb-5">
                    {translations[language].activities_description}
                </p>
            </div>

            {/* Activities Types */}
            <div className="flex justify-center items-center mt-4 space-x-14">
                <ActivityTypeButton activityType={translations[language].activities_turistic_category} icon={turisticIcon} onClick={handleTuristicClick} />
                <ActivityTypeButton activityType={translations[language].activities_social_category} icon={socialIcon} onClick={handleSocialClick} />
                <ActivityTypeButton activityType={translations[language].activities_academic_category} icon={academicIcon} onClick={handleAcademicClick} />
            </div>
        </div>
    </div>
);
}

