import React, { useState } from "react";
import "./RegisterBox.css";
import { useNavigate } from "react-router-dom";
import PasswordRegister from "../components/password/password";
import RegEmailPrompt from "../components/email/regInputBox/RegEmailPrompt";
import UsernamePrompt from "../components/user/UsernamePrompt";
import { useLanguage } from "../../../utils/languageContext";
import { isEmail, isMailAvailable } from "../../behaviour/email";

const checkMail = async (email) => {
	if (!isEmail(email)) return;
	if (await isMailAvailable(email)) {
		alert("El mail ya está registrado!");
		return false;
	}
	return true;
};

const isPassword = (password, passwordConfirm) => {
  if (password == null || password.length < 8) {
    alert("La contraseña es inválida! Tiene que tener al menos 8 caracteres.");
    return false;
  }
  if (
    password !== passwordConfirm &&
    password.length > 0 &&
    passwordConfirm.length > 0
  ) {
    alert("Las contraseñas no coinciden!");
    return false;
  }
  const regex = /^[\p{L}\p{N}@+_~!$]+$/u;
  if (!regex.test(password)) {
    alert(
      "La contraseña no es válida!\nLos caracteres permitidos son: letras, números y los siguientes caracteres especiales: _, ~, !, $, @"
    );
    return false;
  }
  return true;
};

const isUsernameAvailable = async (username) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/check-username/${username}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // El body se enviará en formato JSON
        },
      }
    );

    const data = await response.json();
    return data.isTaken;
  } catch (error) {
    console.error("Error en la solicitud:", error);
    return true;
  }
};

const checkUsername = async (username) => {
  if (username == null || username.length < 4) {
    alert("Nombre de usuario no válido! Tiene que tener al menos 4 caracteres");
    return false;
  }
  if (await isUsernameAvailable(username)) {
    alert("El nombre de usuario no esta disponible! Por favor escoge otro.");
    return false;
  }
  return true;
};

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const navigate = useNavigate(); // Para la navegación
  const { language, translations } = useLanguage();

  const handleVerification = () => {
    navigate(`/Verification`);
  };

  const checkData = async () => {
    const fullMail = email + domain.replace(/ /g, "");
    if (!checkUsername(username)) {
      return;
    }

    const check = await checkMail(fullMail);
    if (check === false) {
      return;
    }

    if (!isEmail(fullMail) || !isPassword(password, passwordConfirm)) return;

    const body = { username, password, fullMail };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // El body se enviará en formato JSON
          },
          body: JSON.stringify(body), // Convertimos el objeto JS en una cadena JSON
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Usuario registrado con éxito:", data);
      } else {
        console.error("Error al registrar usuario:", data.message);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }

    handleVerification();
  };

  return (
    <section className="register-box">
      <h1 id="title">
        {translations[language].register_button}
      </h1>
      <UsernamePrompt onUsernameChange={(e) => setUsername(e.target.value)} />
      <RegEmailPrompt
        onDataChange={(e) => setEmail(e.target.value)}
        onDomainChange={(e) => setDomain(e)}
      />
      <PasswordRegister
        onPasswordChange={(e) => setPassword(e.target.value)}
        onPasswordConfirmChange={(e) => setPasswordConfirm(e.target.value)}
      />

      <input
        type="submit"
        value={translations[language].register_button}
        id="registerBtn"
        onClick={checkData}
      />
    </section>
  );
};

export default Register;
