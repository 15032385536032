import React, { useState } from 'react';
import { supabase } from '../../../../supabaseClient';
import ActivityFloatingButton from '../ActivityFloatingButton';
import { useParams } from "react-router-dom";

function TuristicSetFloatingButton() {
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);

    const { university_id} = useParams();  // Obtenemos el university_id desde la URL. Este es el que uso para insertar en la tabla de la actividad

    const handleButtonClick = () => setShowForm(true);
    const handleFormClose = () => {
        setShowForm(false);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            const { data: imageData, error: imageError } = await supabase
                .from('image')
                .insert([{ base64image: image, name }])
                .select();
                
                
            if (imageError) {
                console.error("Error uploading image:", imageError);
            } else {
                console.log("Image uploaded successfully:", imageData);
            }

            if (imageError) throw imageError;
    
            const image_id = imageData[0].id;

            //Inserto la actividad en la tabla activity_turistic
            const { error: activityError } = await supabase.from('activity_turistic').insert([{ name, description, id_image: image_id, university_id }]).select();
            if(activityError) throw activityError;

            //Si llega aca es porque todo salió bien
            alert('Actividad agregada exitosamente!Refresque la página para verlo.');
            handleFormClose();
        } catch (error) {
            console.error( 'Error al agregar categoría:', error.message);
        }

    };

    return (
        <ActivityFloatingButton
            showForm={showForm}
            handleButtonClick={handleButtonClick}
            handleFormClose={handleFormClose}
            handleSubmit={handleSubmit}
            handleImageUpload={handleImageUpload}
            setName={setName}
            setDescription={setDescription}
        />
    );
}

export default TuristicSetFloatingButton;